import { useParams } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useForm } from "react-hook-form";
import { useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";

const AdminViewExam = () => {
  const { examID } = useParams();

  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const [dataSuccess, ErrorSuccess, LoadingSuccess] = useAxios(
    "https://api.nawaracademy.com/api/admin/trialexam/addstudent",
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );
  const onSubmit = (data) => {
    let finalData = {
      ...data,
      exam_id: examID,
    };
    setSubmitAdd({
      flag: "Add Student",
      dependency: !submitAdd.dependency,
      data: finalData,
    });
    reset();
  };
  const table = useTable(
    "viewStudentExam",
    "",
    "",
    submitAdd.dependency,
    "",
    examID
  );

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
      <HelmetTags title="Exams | Nawar Academy"></HelmetTags>
      <h2 className="mb-20 w-full  text-4xl font-bold md:text-center">
        All Students
      </h2>
      <div className="md:max-w-3/4 flex flex-col w-3/4 md:w-full md:px-5 false mx-auto py-10">
        <div className="flex w-full shadow-lg shadow-blue-200/50">
          <div className="flex-1 flex w-full flex-col items-center ">
            <form
              className="w-full flex  flex-col items-start  justify-center gap-6 p-10"
              onSubmit={handleSubmit(onSubmit)}
            >
              <label htmlFor="">Mobile Number</label>
              <input
                type="text"
                placeholder="Mobile Number"
                className="signin-inputs signin-input"
                {...register("number", { required: true })}
              />
              <button className="submit" type="submit">
                Add Student
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="h-fit  w-full">{table}</div>
    </section>
  );
};

export default AdminViewExam;

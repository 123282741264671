
import { useParams } from 'react-router-dom';
import useTable from '../../../../MainComponents/Hooks/useTable'

export default function AdminExamAbsent() {
    const {examID} = useParams();
    const table = useTable("absentstudnets","","","","",examID);
  return (
    <section className='px-10 py-10'>
        <div>{table}</div>
    </section>
  )
}

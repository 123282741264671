//!you may need to change record.key to record.key
import { Popconfirm, Table, Form, Input, Tag, Tooltip } from "antd";
import { useReducer, useEffect } from "react";
import axios from "axios";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import useWindowSize from "./useWindowSize";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthState, useAuthDispatch } from "../GlobalContext.jsx";
import moment from "moment/moment.js";
import { BsLinkedin } from "react-icons/bs";

function useTable(
  tableFor,
  nested,
  sessionID,
  dependency1,
  dependency2,
  params,
  ifExternal,
  externalData,
  refetch,
  setReFetch,
  noSelect
) {
  const AuthState = useAuthState();
  const AuthDispatch = useAuthDispatch();
  const { examID } = useParams();

  const navigate = useNavigate();
  const { width } = useWindowSize();
  useEffect(() => {
    if (width && width < 800) {
      dispatch({ type: "setSmallWindow", payload: true });
    } else {
      dispatch({ type: "setSmallWindow", payload: false });
    }
  }, [width]);

  let columns;
  let api;

  switch (tableFor) {
    case "codes":
      api = process.env.REACT_APP_ALL_CODES_API;
      break;
    case "activationCodes":
      api = process.env.REACT_APP_ALL_ACTIVATION_CODES_API;
      break;
    case "vodafoneCodes":
      api = process.env.REACT_APP_ALL_VODAFONE_CODES_API;
      break;
    case "revisionCodes":
      api = `${process.env.REACT_APP_ALL_REVISION_CODES_API}/${params}`;
      break;
    case "absentstudnets":
      api = `${process.env.REACT_APP_ABSENT_STUDENTS_API}${params}`;
      break;
    case "revisionCodes":
      api = `${process.env.REACT_APP_ALL_REVISION_CODES_API}/${params}`;
      break;
    case "groups":
      api = process.env.REACT_APP_ALL_GROUPS_API;
      break;
    case "reservations":
      api = process.env.REACT_APP_ADMIN_GET_RESERVATION_API;
      break;
    case "reservationStudent":
      api = `${process.env.REACT_APP_ADMIN_GET_RESERVATION_STUDENT_API}/${params}`;
      break;
    case "appointmentStudent":
      api = `${process.env.REACT_APP_ADMIN_GET_APPOINTMENT_STUDENT_API}/${params}`;
      break;
    case "appointments":
      api = `${process.env.REACT_APP_ADMIN_GET_APPOINTMENTS_API}/${params}`;
      break;
    case "discount-students":
      api = process.env.REACT_APP_ALL_DISCOUNT_STUDENTS_API;
      break;
    case "deletedStudents":
      api = process.env.REACT_APP_ALL_DELETED_STUDENTS_API;
      break;
    case "blockedStudents":
      api = process.env.REACT_APP_ADMIN_GET_BLOCKED_STUDENT_API;
      break;
    case "viewOnlineExamStudents":
      api = `${process.env.REACT_APP_STUDENT_GET_ALL_ONLINE_EXAMS_API}/${params}`;
      break;
    case "viewCenterExamStudents":
      api = `${process.env.REACT_APP_STUDENT_GET_ALL_CENTER_EXAMS_API}/${params}`;
      break;

    case "QuestionBank":
      api = process.env.REACT_APP_ALL_CATEGORIES_API;
      break;
    // TODO: change API
    case "studentQuestionBank":
      api = process.env.REACT_APP_ALL_CATEGORIES_API;
      break;
    case "attendance":
      api = process.env.REACT_APP_ADMIN_ATTENDANCE_TAB_API;
      break;

    case "assistants":
      api = process.env.REACT_APP_ALL_ASSISTANTS_API;
      break;

    case "students":
      api = `${process.env.REACT_APP_ADMIN_STUDENTS_TAB_API}${params}`;
      break;
    case "materials":
      api = `${process.env.REACT_APP_ADMIN_ALL_MATERIALS_API}${params}`;
      break;
    case "onlineAttendanceGroup":
      api = `${process.env.REACT_APP_ADMIN_ALL_STUDENTS_ATTENDENCE_GROUP_API}/${params}`;
      break;
    case "homeworkSets":
      api = `${process.env.REACT_APP_ADMIN_HOMEWORK_SETS_API}${params}`;
      break;
    case "quizSets":
      api = `${process.env.REACT_APP_ADMIN_QUIZ_SETS_API}${params}`;
      break;
    case "examSets":
      api = `${process.env.REACT_APP_ADMIN_TRIAL_EXAM_SETS_API}${params}`;
      break;
    case "analytics":
      api = process.env.REACT_APP_ADMIN_ANALYTICS_All_API;
      break;
    case "sessionAbsence":
      api = `${process.env.REACT_APP_ADMIN_SESSICONS_ABSENCE_API}${params.groupID}/${params.lectureID}`;
      break;

    case "lectures":
      api = `${process.env.REACT_APP_ADMIN_VIEW_LECTURE_TAB_API}/${params}`;
      break;
    case "courses":
      api = `${process.env.REACT_APP_ADMIN_VIEW_LECTURE_TAB_API}/${params}`;
      break;

    case "students-courses":
      api = process.env.REACT_APP_ALL_STUDENTS_COURSES_API;
      break;
    case "purchaseHistory":
      api = `${process.env.REACT_APP_GET_EDIT_STUDENT_PURCHASE_HISTORY_API}/${params}`;
      break;
    case "trialExamStudent":
      api = `${process.env.REACT_APP_GET_EDIT_STUDENT_EXAMS_HISTORY_API}/${params}`;
      break;
    case "exceptionStudent":
      api = `${process.env.REACT_APP_GET_ALL_STUDENT_EXCEPTION_HISTORY_API}/${params}`;
      break;
    case "examExceptionStudent":
      api = `${process.env.REACT_APP_GET_ALL_STUDENT_EXAM_EXCEPTION_HISTORY_API}/${params}`;
      break;
    case "permissionStudent":
      api = `${process.env.REACT_APP_GET_ALL_STUDENT_PERMISSION_HISTORY_API}/${params}`;
      break;
    case "viewStudentExam":
      api = `${process.env.REACT_APP_ADMIN_VIEW_STUDENTS_EXAM_API}/${params}`;
      break;

    // case "attendanceGroup":
    //   api = `${process.env.REACT_APP_ADMIN_VIEW_STUDENTS_EXAM_API}/${params}`;
    //   break;
    // case "quizzesGroup":
    //   api = `${process.env.REACT_APP_ADMIN_VIEW_STUDENTS_EXAM_API}/${params}`;
    //   break;
    // case "examsGroup":
    //   api = `${process.env.REACT_APP_ADMIN_VIEW_STUDENTS_EXAM_API}/${params}`;
    //   break;
    case "groupsGroup":
      api = `${process.env.REACT_APP_ADMIN_VIEW_STUDENTS_EXAM_API}/${params}`;
      break;

    default:
      api = process.env.REACT_APP_ALL_ASSISTANTS_API;
      break;
  }

  function reducer(state, action) {
    switch (action.type) {
      case "setData": {
        return {
          ...state,
          data: action.payload,
        };
      }
      case "setForFilterData": {
        return {
          ...state,
          forFilterData: action.payload,
        };
      }

      case "SetLoading": {
        return {
          ...state,
          loading: action.payload,
        };
      }
      case "SetShowSidebar": {
        return {
          ...state,
          showSidebar: !state.showSidebar,
        };
      }

      case "SetEditRowKey": {
        return {
          ...state,
          editRowKey: action.payload,
        };
      }
      case "SetEditableQuizKey": {
        return {
          ...state,
          editableQuizKey: action.payload,
        };
      }
      case "SetSortingOptions": {
        return {
          ...state,
          sortingOptions: action.payload,
        };
      }
      case "SetQuery": {
        return {
          ...state,
          query: action.payload,
        };
      }
      case "SetColumnQuery": {
        return {
          ...state,
          columnQuery: action.payload,
        };
      }
      case "SetQueriedColumn": {
        return {
          ...state,
          queriedColumn: action.payload,
        };
      }
      case "SetFilteredInfo": {
        return {
          ...state,
          filteredInfo: action.payload,
        };
      }
      case "setSelectedRows": {
        return {
          ...state,
          selectedRows: action.payload,
        };
      }
      case "setSmallWindow": {
        return {
          ...state,
          smallWindow: action.payload,
        };
      }
      case "setUpdatingType": {
        return {
          ...state,
          updatingType: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const initialState = {
    data: [],
    forFilterData: [],
    loading: false,
    editRowKey: "",
    editableQuizKey: "",
    sortingOptions: {},
    query: "",
    columnQuery: "",
    queriedColumn: "",
    filteredInfo: {},
    selectedRows: [],
    smallWindow: false,
    updatingType: "",
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  async function fetchData() {
    if (ifExternal) {
      dispatch({ type: "setData", payload: externalData });
      dispatch({ type: "setForFilterData", payload: externalData });
      //console.log(externalData);

      dispatch({ type: "SetLoading", payload: false });
    } else {
      try {
        const res = await axios.get(api, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              AuthState.userData.student_token || AuthState.userData.admin_token
            }`,
            /* Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`, */
            //"Content-Type": "multipart/form-data",
          },
          //withCredentials: true,
        });
        const data = await res.data;

        dispatch({ type: "setData", payload: data.data });
        dispatch({ type: "setForFilterData", payload: data.data });

        dispatch({ type: "SetLoading", payload: false });
      } catch (error) {
        toast.error(
          error?.response?.data?.message ||
            "An error occurred while loading data"
        );

        dispatch({ type: "SetLoading", payload: false });
        //!need to handle the case of delete the last item in the table and refetching again, so the refetch request will response with error no data and the last data will stay in the page but it must be removed whereas the item has deleted
        dispatch({ type: "setData", payload: [] });
      }
    }
  }

  useEffect(() => {
    dispatch({ type: "SetLoading", payload: true });

    fetchData();
  }, [dependency1, dependency2]);
  const [form] = Form.useForm();

  async function handleDelete(record) {
    let deleteApi;
    switch (tableFor) {
      case "codes":
        deleteApi = process.env.REACT_APP_DELETE_CODE_API;
        break;
      case "activationCodes":
        deleteApi = process.env.REACT_APP_DELETE_ACTIVATION_CODE_API;
        break;
      case "revisionCodes":
        deleteApi = process.env.REACT_APP_DELETE_REVISION_CODE_API;
        break;
      case "vodafoneCodes":
        deleteApi = process.env.REACT_APP_DELETE_CODE_API;
        break;
      case "groups":
        deleteApi = process.env.REACT_APP_DELETE_GROUP_API;
        break;
      case "reservations":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_RESERVATION_API;
        break;
      // case "reservationStudent":
      //   deleteApi = process.env.REACT_APP_ADMIN_DELETE_RESERVATION_STUDENT_API;
      //   break;
      case "appointments":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_APPOINTMENTS_API;
        break;
      case "QuestionBank":
        deleteApi = process.env.REACT_APP_DELETE_CATEGORY_API;
        break;
      case "attendance":
        deleteApi = "https://groups-api.onrender.com/data";
        break;
      case "deletedStudents":
        deleteApi = process.env.REACT_APP_ALL_DELETED_STUDENTS_DELETE_API;
        break;
      case "assistants":
        deleteApi = process.env.REACT_APP_DELETE_ASSISTANT_API;
        break;
      case "sessionAttendance":
        deleteApi =
          process.env
            .REACT_APP_ADMIN_SINGLE_SESSION_ATTENDANCE_TABLE_DELETE_ROW_API;
        break;
      case "students":
        deleteApi = process.env.REACT_APP_ALL_DELETED_STUDENTS_DELETE_API;
        break;
      case "studentsOnlineInGroup":
        deleteApi = process.env.REACT_APP_ALL_DELETED_STUDENTS_DELETE_API;
        break;
      case "students-courses":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_STUDENT_API;
        break;
      case "materials":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_MATERIAL_API;
        break;
      case "homeworkSets":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_SET_API;
        break;
      case "quizSets":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_SET_API;
        break;
      case "examSets":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_SET_API;
        break;
      case "viewStudentExam":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_STUDENT_TRIAL_EXAM_API;
        break;
      case "lectures":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_STUDENT_SESSION_API;
        break;
      case "courses":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_STUDENT_SESSION_API;
        break;
      case "purchaseHistory":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_STUDENT_SESSION_API;
        break;

      default:
        deleteApi = "https://students-api-g0pp.onrender.com/data";
        break;
    }

    const options = {
      url: deleteApi,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        //Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`,
        "Content-Type": "multipart/form-data",
      },
      data: { id: tableFor === "purchaseHistory" ? record.id : record.key },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        if (ifExternal) {
          setReFetch(!refetch);
        } else {
          fetchData();
          if (tableFor === "students") {
            AuthDispatch({
              type: "setRefetchIfAcceptOrDeleteRequest",
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  async function handleOutStudent(record) {
    const options = {
      url: process.env.REACT_APP_ADMIN_DELETE_STUDENT_API,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        //Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`,
        "Content-Type": "multipart/form-data",
      },
      data: { id: record.key },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        if (ifExternal) {
          setReFetch(!refetch);
        } else {
          fetchData();
          if (tableFor === "students") {
            AuthDispatch({
              type: "setRefetchIfAcceptOrDeleteRequest",
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  // delete === force Delete
  async function handleGiveChance(record) {
    const options = {
      url: process.env.REACT_APP_ADMIN_GIVE_CHANCE_STUDENT_API,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        //Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`,
        "Content-Type": "multipart/form-data",
      },
      data: { history_id: record.key },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        if (ifExternal) {
          setReFetch(!refetch);
        } else {
          fetchData();
          if (tableFor === "students") {
            AuthDispatch({
              type: "setRefetchIfAcceptOrDeleteRequest",
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  async function handleRestore(record) {
    const options = {
      url: process.env.REACT_APP_ALL_DELETED_STUDENTS_RESTORE_API,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        //Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`,
        "Content-Type": "multipart/form-data",
      },
      data: { id: record.key },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        fetchData();
        AuthDispatch({
          type: "setRefetchIfAcceptOrDeleteRequest",
        });
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Something Wrong has Happened!"
      );
    }
  }

  async function handleBlockStudent(record) {
    let blockApi;
    switch (tableFor) {
      case "students":
        blockApi = process.env.REACT_APP_ADMIN_BLOCK_STUDENT_API;
        break;
      case "analyticsBlocking":
        blockApi = process.env.REACT_APP_ADMIN_BLOCK_STUDENT_API;
        break;

      default:
        blockApi = "https://students-api-g0pp.onrender.com/data";
        break;
    }

    const options = {
      url: blockApi,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        //Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`,
        "Content-Type": "multipart/form-data",
      },
      data: { id: record.key },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        if (ifExternal) {
          setReFetch(!refetch);
        } else {
          fetchData();
          if (tableFor === "students") {
            AuthDispatch({
              type: "setRefetchIfAcceptOrDeleteRequest",
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  async function handleUnBlockStudent(record) {
    let unBlockApi;
    switch (tableFor) {
      case "blockedStudents":
        unBlockApi = process.env.REACT_APP_ADMIN_UNBLOCK_STUDENT_API;
        break;

      default:
        unBlockApi = "https://students-api-g0pp.onrender.com/data";
        break;
    }

    const options = {
      url: unBlockApi,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        //Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`,
        "Content-Type": "multipart/form-data",
      },
      data: { id: record.key },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        if (ifExternal) {
          setReFetch(!refetch);
        } else {
          fetchData();
          if (tableFor === "students") {
            AuthDispatch({
              type: "setRefetchIfAcceptOrDeleteRequest",
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  async function handleLogoutStudent(record) {
    let logoutApi;
    switch (tableFor) {
      case "students":
        logoutApi = process.env.REACT_APP_ADMIN_LOGOUT_STUDENT_API;
        break;

      default:
        logoutApi = "https://students-api-g0pp.onrender.com/data";
        break;
    }

    const options = {
      url: logoutApi,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        //Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`,
        "Content-Type": "multipart/form-data",
      },
      data: { id: record.key },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        if (ifExternal) {
          setReFetch(!refetch);
        } else {
          fetchData();
          if (tableFor === "students") {
            AuthDispatch({
              type: "setRefetchIfAcceptOrDeleteRequest",
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
  /**
//!we check if the current id stored in the editRowKey is equal to the record id to know which row is going to be updated
 */

  function isEditing(record) {
    return record.key === state.editRowKey;
  }

  /**
//!here we reset the editRowKey to indicate that no row is going to be update and hide the cancel and save buttons then show the edit and delete buttons again 
 */

  async function handleUpdateRequest(data) {
    let editApi;
    let finalData = data;

    if (tableFor === "sessionAttendance") {
      if (state.updatingType === "quiz") {
        editApi =
          process.env
            .REACT_APP_ADMIN_SINGLE_SESSION_ATTENDANCE_UPDATE_STUDENT_QUIZ_DEGREE_API;
        const { id, student_quiz_degree } = data;
        finalData = { id: id, degree: student_quiz_degree };
      } else if (state.updatingType !== "quiz") {
        editApi =
          process.env
            .REACT_APP_ADMIN_SINGLE_SESSION_ATTENDANCE_UPDATE_STUDENT_HOMEWORK_API;
      }
    } else {
      switch (tableFor) {
        case "codes":
          editApi = process.env.REACT_APP_EDIT_CODE_API;
          break;
        case "vodafoneCodes":
          editApi = process.env.REACT_APP_EDIT_CODE_API;
          break;
        case "revisionCodes":
          editApi = process.env.REACT_APP_EDIT_REVISION_CODE_API;
          break;
        case "groups":
          editApi = process.env.REACT_APP_EDIT_GROUP_API;
          break;
        case "reservations":
          editApi = process.env.REACT_APP_ADMIN_EDIT_RESERVATION_API;
          break;
        case "appointments":
          editApi = process.env.REACT_APP_ADMIN_EDIT_APPOINTMENTS_API;
          break;
        case "discount-students":
          editApi = process.env.REACT_APP_EDIT_DISCOUNT_STUDENTS_API;
          break;
        case "QuestionBank":
          editApi = process.env.REACT_APP_EDIT_CATEGORY_API;
          break;
        case "attendance":
          editApi = "https://groups-api.onrender.com/data";
          break;

        case "assistants":
          editApi = process.env.REACT_APP_ALL_ASSISTANTS_API;
          break;
        case "viewOnlineExamStudents":
          editApi = process.env.REACT_APP_ADD_EDIT_ONLINE_EXAM_API;
          break;
        case "viewCenterExamStudents":
          editApi = process.env.REACT_APP_ADD_EDIT_ONLINE_EXAM_API;
          break;
        case "homeworkSets":
          editApi = process.env.REACT_APP_ADMIN_EDIT_SET_API;
          break;
        case "quizSets":
          editApi = process.env.REACT_APP_ADMIN_EDIT_SET_API;
          break;
        case "examSets":
          editApi = process.env.REACT_APP_ADMIN_EDIT_SET_API;
          break;
        case "lectures":
          editApi = process.env.REACT_APP_ADMIN_EDIT_STUDENT_SESSION_API;
          break;
        case "courses":
          editApi = process.env.REACT_APP_ADMIN_EDIT_STUDENT_SESSION_API;
          break;

        default:
          editApi = "https://students-api-g0pp.onrender.com/data";
          break;
      }
    }
    const options = {
      url: editApi,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        //Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`,
        "Content-Type": "multipart/form-data",
      },
      data: finalData,
    };
    const res = await axios(options);
    return await res.data;
  }
  function handleCancelEdit() {
    dispatch({ type: "SetEditRowKey", payload: "" });
  }
  //!  value is for homework update from radio button ----
  async function handleSaveEdit(record, value) {
    //console.log(record);
    const key = record.key;

    try {
      const row = value ? { homework: value } : await form.validateFields();
      //console.log("row", row);
      const res = await handleUpdateRequest({
        ...row,
        id: key,
        exam_id: examID,
      });
      toast.success(res.message);
      dispatch({ type: "SetEditRowKey", payload: "" });
      dispatch({ type: "SetEditableQuizKey", payload: "" });
      dispatch({ type: "setUpdatingType", payload: "" });

      if (ifExternal) {
        setReFetch(!refetch);
      } else {
        fetchData();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
  //! -- to prevent sending request from onBlur of all editable inputs ---
  function handleUpdateQuiz(record) {
    if (state.editableQuizKey === record.key) {
      handleSaveEdit(record);
    }
  }
  function handleUpdateHomework(record, value) {
    handleSaveEdit(record, value);
  }

  function handleQuizDoubleClicked(record) {
    console.log(record);
    dispatch({ type: "SetEditableQuizKey", payload: record.key });
    dispatch({ type: "setUpdatingType", payload: "quiz" });
    handleEdit(record);
  }
  function handleEdit(record) {
    form.setFieldsValue({
      /* firstname: "",
      username: "",

      password: "",
      governorate: "",
      city: "",
      academiclevel: "",
      school: "",
      activationcode: "", */
      ...record,
    });

    dispatch({ type: "SetEditRowKey", payload: record.key });
  }
  function handleResetSortFilterSearch(record) {
    dispatch({ type: "SetSortingOptions", payload: {} });
    dispatch({
      type: "SetFilteredInfo",
      payload: {},
    });
    dispatch({
      type: "SetQuery",
      payload: "",
    });
    fetchData();
  }

  function editableCell({
    editing,
    dataIndex,
    title,
    record,
    children,
    ...restProps
  }) {
    const input = <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            onBlur={() => handleUpdateQuiz(record)}
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[{ required: false }]}
          >
            {input}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  //!to save the sorting options and retrieve then reset it from reset button

  function handleTableChange(_, filter, sorter) {
    const { order, field } = sorter;
    dispatch({
      type: "SetSortingOptions",
      payload: { columnKey: field, order },
    });
    dispatch({
      type: "SetFilteredInfo",
      payload: filter,
    });
  }

  function handleGlobalSearch(e) {
    dispatch({ type: "SetQuery", payload: e.target.value });

    const filteredData = state?.forFilterData?.filter((item) => {
      return (
        Object.values(item)[0]
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase()) ||
        Object.values(item)[1]
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[2]
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[3]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[4]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[5]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[6]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[7]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[8]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[9]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[10]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[11]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[12]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[13]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        Object.values(item)[14]
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      );
    });

    dispatch({ type: "setData", payload: filteredData });
  }

  function handleColumnSearch(selectedKeys, confirm, dataIndex) {
    confirm({ closeDropdown: false });
    dispatch({ type: "SetColumnQuery", payload: selectedKeys[0] });
    dispatch({ type: "SetQueriedColumn", payload: dataIndex });
  }
  function handleColumnReset(clearFilters, confirm) {
    clearFilters();
    dispatch({ type: "SetColumnQuery", payload: "" });
    dispatch({ type: "SetQueriedColumn", payload: "" });
    confirm();
  }

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className="flex h-40 w-72 flex-col justify-center gap-5 bg-light p-4">
        <Input
          className="signin-inputs signin-input search-table-placeholder text- h-10 pl-4 placeholder:!text-xl"
          autoFocus
          placeholder={` البحث في ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleColumnSearch(selectedKeys, confirm, dataIndex);
            }
          }}
        />
        <div className="flex justify-center gap-5 ">
          <button
            onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
            className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
          >
            بحث
          </button>
          <button
            onClick={() => handleColumnReset(clearFilters, confirm)}
            className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
          >
            Delete
          </button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <FontAwesomeIcon
        className={`${filtered ? "text-secondary" : "text-save"}`}
        icon={faMagnifyingGlass}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",

    render: (text) =>
      state.queriedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#f9b522", padding: 0 }}
          searchWords={[state.columnQuery]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  async function handleRetakeExam(record) {
    const options = {
      url: process.env.REACT_APP_ADMIN_RETAKE_TRIAL_EXAM_API,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        "Content-Type": "multipart/form-data",
      },
      data: { id: record.key },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        if (ifExternal) {
          setReFetch(!refetch);
        } else {
          fetchData();
          if (tableFor === "students") {
            AuthDispatch({
              type: "setRefetchIfAcceptOrDeleteRequest",
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  //!------------------ Columns --------------------

  const codesColumns = [
    {
      title: "numbering",
      dataIndex: "n",
      editTable: false,
      width: 120,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "serial",
      dataIndex: "key",
      editTable: false,
      width: 120,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.key || null,

      ...getColumnSearchProps("key", "key"),
      sorter: (a, b) => a.key - b.key,
      sortOrder:
        state.sortingOptions.columnKey === "key" && state.sortingOptions.order,
    },
    {
      title: "Code",
      dataIndex: "code",
      filteredValue: state.filteredInfo.code || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("code", "Code"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.code.length - b.code.length,
      sortOrder:
        state.sortingOptions.columnKey === "code" && state.sortingOptions.order,
    },
    {
      title: "Price",
      dataIndex: "price",
      editTable: true,
      width: 100,
      align: "center",
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      width: 150,
      align: "center",
    },
    {
      title: "Creation date",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "Creation date"),
      width: 150,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("code_delete")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm deletion action?"
                  >
                    <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("code_edit")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];
  const absentstudnetsColumns = [
    {
      title: "Name",
      dataIndex: "name",
      editTable: false,
      width: 120,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.name || null,

      ...getColumnSearchProps("name", "Column Name"),
      sorter: (a, b) => a.name - b.name,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      editTable: false,
      width: 120,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.mobile || null,

      ...getColumnSearchProps("mobile", "Mobile"),
      sorter: (a, b) => a.mobile - b.mobile,
      sortOrder:
        state.sortingOptions.columnKey === "mobile" &&
        state.sortingOptions.order,
    },
    {
      title: "Mother's Mobile",
      dataIndex: "mother_mobile",
      editTable: false,
      width: 120,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.mother_mobile || null,

      ...getColumnSearchProps("mother_mobile", "Mother's Mobile"),
      sorter: (a, b) => a.mother_mobile - b.mother_mobile,
      sortOrder:
        state.sortingOptions.columnKey === "mother_mobile" &&
        state.sortingOptions.order,
    },
    {
      title: "Type",
      dataIndex: "type",
      editTable: false,
      width: 120,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.type || null,

      ...getColumnSearchProps("type", "Type"),
      sorter: (a, b) => a.type - b.type,
      sortOrder:
        state.sortingOptions.columnKey === "type" && state.sortingOptions.order,
    },
  ];
  const activationCodesColumns = [
    {
      title: "Number",
      dataIndex: "n",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "Number"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "ID",
      dataIndex: "key",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("key", "key"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },

    {
      title: "Code",
      dataIndex: "code",
      filteredValue: state.filteredInfo.code || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("code", "Code"),
      editTable: true,
      width: 70,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.code.length - b.code.length,
      sortOrder:
        state.sortingOptions.columnKey === "code" && state.sortingOptions.order,
    },

    {
      title: "Created At",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "Created At"),
      width: 60,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 30,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("code_delete")
              )) && (
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#ffffff"
                //okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleDelete(record)}
                title="Are you sure you want to delete?"
              >
                <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                  Delete
                </button>
              </Popconfirm>
            )}
          </div>
        ) : null;
      },
    },
  ];
  const revisionCodesColumns = [
    {
      title: "numbering",
      dataIndex: "n",
      editTable: false,
      width: 120,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "serial",
      dataIndex: "key",
      editTable: false,
      width: 120,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.key || null,

      ...getColumnSearchProps("key", "key"),
      sorter: (a, b) => a.key - b.key,
      sortOrder:
        state.sortingOptions.columnKey === "key" && state.sortingOptions.order,
    },
    {
      title: "Code",
      dataIndex: "code",
      filteredValue: state.filteredInfo.code || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("code", "Code"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.code.length - b.code.length,
      sortOrder:
        state.sortingOptions.columnKey === "code" && state.sortingOptions.order,
    },
    {
      title: "Student Name",
      dataIndex: "student_name",
      editTable: false,
      width: 200,
      align: "center",
    },
    {
      title: "Views",
      dataIndex: "views",
      editTable: true,
      width: 100,
      align: "center",
    },
    {
      title: "Expiration Days",
      dataIndex: "expire_after",
      editTable: true,
      width: 150,
      align: "center",
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      width: 150,
      align: "center",
    },
    {
      title: "Creation date",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "Creation date"),
      width: 150,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("code_delete")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm deletion action?"
                  >
                    <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {!record.student_name && (
                  <>
                    {(AuthState.userData?.admin?.roles[0]?.name ===
                      "superadmin" ||
                      AuthState.userData?.admin?.permissions?.some(
                        (permission) => permission.name.startsWith("code_edit")
                      )) && (
                      <button
                        className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                        onClick={() => handleEdit(record)}
                      >
                        Edit
                      </button>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];
  const vodafoneCodesColumns = [
    {
      title: "numbering",
      dataIndex: "n",
      editTable: false,
      width: 120,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "serial",
      dataIndex: "key",
      editTable: false,
      width: 120,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.key || null,

      ...getColumnSearchProps("key", "key"),
      sorter: (a, b) => a.key - b.key,
      sortOrder:
        state.sortingOptions.columnKey === "key" && state.sortingOptions.order,
    },
    {
      title: "Code",
      dataIndex: "code",
      filteredValue: state.filteredInfo.code || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("code", "Code"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.code.length - b.code.length,
      sortOrder:
        state.sortingOptions.columnKey === "code" && state.sortingOptions.order,
    },
    {
      title: "Price",
      dataIndex: "price",
      editTable: true,
      width: 100,
      align: "center",
    },
    {
      title: "Used By",
      dataIndex: "used_by",
      width: 200,
      align: "center",
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      width: 150,
      align: "center",
    },
    {
      title: "Creation date",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "Creation date"),
      width: 150,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("code_delete")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm deletion action?"
                  >
                    <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("code_edit")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];

  const discountStudentsColumns = [
    {
      title: "n",
      dataIndex: "n",
      width: 120,
      align: "center",
    },

    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.code || null,
      //responsive: ["lg"],
      ...getColumnSearchProps("name", "Name"),
      width: 200,
      align: "center",
    },

    {
      title: "Mobile",
      dataIndex: "mobile",
      //responsive: ["lg"],
      width: 200,
      align: "center",
    },
    {
      title: "Parent Mobile",
      dataIndex: "parent_mobile",
      width: 200,
      align: "center",
    },
    {
      title: "Parent Mobile 2",
      dataIndex: "parent_mobile2",
      width: 200,
      align: "center",
    },
    {
      title: "Group ",
      dataIndex: "group_id",
      width: 200,
      align: "center",
    },
    {
      title: "Stage",
      dataIndex: "stage",
      width: 100,
      align: "center",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      width: 100,
      editTable: true,
      align: "center",
    },

    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("code_edit")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];

  const deletedStudentsColumns = [
    {
      title: "Number",
      dataIndex: "n",
      editTable: false,
      width: 110,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,
      ...getColumnSearchProps("n", "Number"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },

    {
      title: "Name",
      dataIndex: "",
      filteredValue: state.filteredInfo.name || null,
      fixed: state.smallWindow ? false : "left",

      ...getColumnSearchProps("name", "Name"),
      editTable: false,
      width: 200,
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
      render: (_, record) => (
        <Link to={`/admin/students/edit-student/${record.key}`}>
          {record.name}
        </Link>
      ),
    },

    {
      title: "Mobile",
      dataIndex: "mobile",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.mobile || null,
      ...getColumnSearchProps("mobile", "Phone Number"),
      editTable: false,
    },
    {
      title: "Parent mobile 2",
      dataIndex: "parent_mobile2",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.parent_mobile2 || null,
      ...getColumnSearchProps("parent_mobile2", "parent mobile 2"),
      editTable: false,
    },
    {
      title: "Stage",
      dataIndex: "stage",
      filteredValue: state.filteredInfo.stage || null,
      ...getColumnSearchProps("stage", "Stage"),
      editTable: false,
      width: 110,
      align: "center",
      sorter: (a, b) => a.stage.length - b.stage.length,
      sortOrder:
        state.sortingOptions.columnKey === "stage" &&
        state.sortingOptions.order,
    },
    {
      title: "Student Type",
      dataIndex: "is_online",
      filteredValue: state.filteredInfo.is_online || null,
      ...getColumnSearchProps("is_online", "Section"),
      editTable: false,
      width: 110,
      align: "center",
      sorter: (a, b) => a.is_online.length - b.is_online.length,
      sortOrder:
        state.sortingOptions.columnKey === "is_online" &&
        state.sortingOptions.order,
    },
    {
      title: "Last Lecture",
      dataIndex: "last_lecture",
      filteredValue: state.filteredInfo.school || null,
      ...getColumnSearchProps("last_lecture", "Last Lecture"),
      editTable: false,
      width: 200,
      align: "center",
      sorter: (a, b) => a.last_lecture.length - b.last_lecture.length,
      sortOrder:
        state.sortingOptions.columnKey === "last_lecture" &&
        state.sortingOptions.order,
    },

    {
      title: "Options",
      dataIndex: "",
      width: 240,
      align: "center",
      fixed: state.smallWindow ? false : "right",

      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-6">
            <>
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("request_accept")
                )) && (
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#ffffff"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={() => handleDelete(record)}
                  title="Are you sure you want to delete?"
                >
                  <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                    Delete
                  </button>
                </Popconfirm>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("request_accept")
                )) && (
                <button
                  onClick={() => handleRestore(record)}
                  className="rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                >
                  Restore
                </button>
              )}
            </>
          </div>
        ) : null;
      },
    },
  ];
  const blockedStudentsColumns = [
    {
      title: "Number",
      dataIndex: "n",
      editTable: false,
      width: 110,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,
      ...getColumnSearchProps("n", "Number"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },

    {
      title: "Name",
      dataIndex: "",
      filteredValue: state.filteredInfo.name || null,
      fixed: state.smallWindow ? false : "left",

      ...getColumnSearchProps("name", "Name"),
      editTable: false,
      width: 200,
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
      render: (_, record) => (
        <Link to={`/admin/students/edit-student/${record.key}`}>
          {record.name}
        </Link>
      ),
    },

    {
      title: "Mobile",
      dataIndex: "mobile",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.mobile || null,
      ...getColumnSearchProps("mobile", "Phone Number"),
      editTable: false,
    },
    {
      title: "Parent mobile 2",
      dataIndex: "parent_mobile2",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.parent_mobile2 || null,
      ...getColumnSearchProps("parent_mobile2", "parent mobile 2"),
      editTable: false,
    },
    {
      title: "Student Type",
      dataIndex: "is_online",
      filteredValue: state.filteredInfo.is_online || null,
      ...getColumnSearchProps("is_online", "Section"),
      editTable: false,
      width: 110,
      align: "center",
      sorter: (a, b) => a.is_online.length - b.is_online.length,
      sortOrder:
        state.sortingOptions.columnKey === "is_online" &&
        state.sortingOptions.order,
    },
    {
      title: "Stage",
      dataIndex: "stage",
      filteredValue: state.filteredInfo.stage || null,
      ...getColumnSearchProps("stage", "Stage"),
      editTable: false,
      width: 110,
      align: "center",
      sorter: (a, b) => a.stage.length - b.stage.length,
      sortOrder:
        state.sortingOptions.columnKey === "stage" &&
        state.sortingOptions.order,
    },
    {
      title: "Last Session",
      dataIndex: "last_lecture",
      filteredValue: state.filteredInfo.school || null,
      ...getColumnSearchProps("last_lecture", "Last Lecture"),
      editTable: false,
      width: 200,
      align: "center",
      sorter: (a, b) => a.last_lecture.length - b.last_lecture.length,
      sortOrder:
        state.sortingOptions.columnKey === "last_lecture" &&
        state.sortingOptions.order,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 240,
      align: "center",
      fixed: state.smallWindow ? false : "right",

      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-6">
            <>
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("request_accept")
                )) && (
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={() => handleUnBlockStudent(record)}
                  title="Are you sure you want to Un Block?"
                >
                  <button className="rounded-2xl border-2 border-secondary bg-secondary py-1 px-4 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                    Un Block
                  </button>
                </Popconfirm>
              )}
            </>
          </div>
        ) : null;
      },
    },
  ];

  const latedStudentsColumns = [
    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,
      fixed: state.smallWindow ? false : "left",
      ...getColumnSearchProps("name", "Name"),
      editTable: false,
      width: 200,
      align: "center",
    },

    {
      title: "Mobile",
      dataIndex: "mobile",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.mobile || null,
      ...getColumnSearchProps("mobile", "Phone Number"),
      editTable: false,
    },
    {
      title: "Parent mobile 2",
      dataIndex: "mobilep2",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.mobilep2 || null,
      ...getColumnSearchProps("mobilep2", "parent mobile"),
      editTable: false,
    },
  ];

  const groupsColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Group name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "Group name"),
      editTable: true,
      width: 150,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Description",
      dataIndex: "description",
      filteredValue: state.filteredInfo.description || null,
      ellipsis: {
        showTitle: false,
      },
      render: (description) => (
        <Tooltip placement="topLeft" title={description}>
          {description}
        </Tooltip>
      ),

      //responsive: ["lg"],
      ...getColumnSearchProps("description", "description"),
      editTable: true,
      width: 180,
      align: "center",
      sorter: (a, b) => a.description.length - b.description.length,
      sortOrder:
        state.sortingOptions.columnKey === "description" &&
        state.sortingOptions.order,
    },
    {
      title: "Class",
      dataIndex: "stage",
      filteredValue: state.filteredInfo.stage || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("stage", "Class"),
      editTable: true,
      width: 150,
      align: "center",
      sorter: (a, b) => a.stage - b.stage,
      sortOrder:
        state.sortingOptions.columnKey === "stage" &&
        state.sortingOptions.order,
    },
    {
      title: "Creation date",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "Creation date"),
      width: 150,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("group_delete")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm deletion action?"
                  >
                    <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("group_update")
                  )) && (
                  <Link
                    to={`/admin/groups/${record.key}/edit-group`}
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                  >
                    Edit
                  </Link>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];
  const reservationsColumns = [
    {
      title: "Reservation name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,
      editTable: true,
      width: 150,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      render: (_, record) => (
        <Link to={`/admin/reservations/appointments/${record.key}`}>
          {record.name}
        </Link>
      ),
    },

    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                <Link
                  to={`/admin/reservations/students/${record.key}`}
                  className="px-4 rounded-2xl border-2 border-main bg-main py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-main active:scale-90"
                >
                  Students
                </Link>
                <button
                  className="px-4 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                  onClick={() => handleEdit(record)}
                >
                  Edit
                </button>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={() => handleDelete(record)}
                  title="Confirm deletion action?"
                >
                  <button className="px-4 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                    Delete
                  </button>
                </Popconfirm>
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];
  const reservationStudentColumns = [
    {
      title: "N",
      dataIndex: "n",
      width: 150,
      align: "center",
    },
    {
      title: "Student Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,
      width: 150,
      align: "center",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      width: 150,
      align: "center",
    },
    {
      title: "Appoinment",
      dataIndex: "appoinment",
      width: 150,
      align: "center",
    },

    // {
    //   title: "Options",
    //   dataIndex: "",
    //   width: 150,
    //   align: "center",
    //   fixed: state.smallWindow ? false : "left",
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     return state.data.length > 0 ? (
    //       <div className="m-0 flex  justify-center gap-10">
    //         {!editable ? (
    //           <>
    //             <Popconfirm
    //               okText="Confirm"
    //               okType="danger"
    //               cancelText="Cancel"
    //               color="#fecaca"
    //               //okButtonProps={{ loading: state.loading }}
    //               onConfirm={() => handleDelete(record)}
    //               title="Confirm deletion action?"
    //             >
    //               <button className="px-4 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
    //                 Delete
    //               </button>
    //             </Popconfirm>
    //           </>
    //         ) : (
    //           <>
    //             <Popconfirm
    //               okText="Confirm"
    //               okType="danger"
    //               cancelText="Cancel"
    //               color="#fecaca"
    //               //okButtonProps={{ loading: state.loading }}
    //               onConfirm={handleCancelEdit}
    //               title="Confirm cancellation of modification?"
    //             >
    //               <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
    //                 Cancel
    //               </button>
    //             </Popconfirm>
    //             <button
    //               className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
    //               onClick={() => handleSaveEdit(record)}
    //             >
    //               Save
    //             </button>
    //           </>
    //         )}
    //       </div>
    //     ) : null;
    //   },
    // },
  ];
  const appointmentStudentColumns = [
    {
      title: "N",
      dataIndex: "n",
      width: 150,
      align: "center",
    },
    {
      title: "Student Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,
      width: 150,
      align: "center",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      width: 150,
      align: "center",
    },

    // {
    //   title: "Options",
    //   dataIndex: "",
    //   width: 150,
    //   align: "center",
    //   fixed: state.smallWindow ? false : "left",
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     return state.data.length > 0 ? (
    //       <div className="m-0 flex  justify-center gap-10">
    //         {!editable ? (
    //           <>
    //             <Popconfirm
    //               okText="Confirm"
    //               okType="danger"
    //               cancelText="Cancel"
    //               color="#fecaca"
    //               //okButtonProps={{ loading: state.loading }}
    //               onConfirm={() => handleDelete(record)}
    //               title="Confirm deletion action?"
    //             >
    //               <button className="px-4 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
    //                 Delete
    //               </button>
    //             </Popconfirm>
    //           </>
    //         ) : (
    //           <>
    //             <Popconfirm
    //               okText="Confirm"
    //               okType="danger"
    //               cancelText="Cancel"
    //               color="#fecaca"
    //               //okButtonProps={{ loading: state.loading }}
    //               onConfirm={handleCancelEdit}
    //               title="Confirm cancellation of modification?"
    //             >
    //               <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
    //                 Cancel
    //               </button>
    //             </Popconfirm>
    //             <button
    //               className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
    //               onClick={() => handleSaveEdit(record)}
    //             >
    //               Save
    //             </button>
    //           </>
    //         )}
    //       </div>
    //     ) : null;
    //   },
    // },
  ];
  const appointmentsColumns = [
    {
      title: "Appointment name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,
      editTable: true,
      width: 150,
      fixed: state.smallWindow ? false : "left",
      align: "center",
    },

    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                <Link
                  to={`/admin/reservations/appointments/students/${record.key}`}
                  className="px-4 rounded-2xl border-2 border-main bg-main py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-main active:scale-90"
                >
                  Students
                </Link>
                <button
                  className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                  onClick={() => handleEdit(record)}
                >
                  Edit
                </button>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={() => handleDelete(record)}
                  title="Confirm deletion action?"
                >
                  <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                    Delete
                  </button>
                </Popconfirm>
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];
  const QuestionBankColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 110,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Category name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "Category name"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
      render: (_, record) => {
        return AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("questionbank_viewquestionbycategory")
          ) ? (
          <Link to={`${record.key}`}>{record.name}</Link>
        ) : (
          <p>{record.name}</p>
        );
      },
    },
    {
      title: "Class",
      dataIndex: "stage",
      filteredValue: state.filteredInfo.stage || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("stage", "Class"),
      editTable: true,
      width: 150,
      align: "center",
      sorter: (a, b) => a.stage - b.stage,
      sortOrder:
        state.sortingOptions.columnKey === "stage" &&
        state.sortingOptions.order,
    },
    {
      title: "Easy",
      dataIndex: "easy",
      filteredValue: state.filteredInfo.easy || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("easy", "Easy"),
      width: 100,
      align: "center",
      sorter: (a, b) => a.easy - b.easy,
      sortOrder:
        state.sortingOptions.columnKey === "easy" && state.sortingOptions.order,
    },
    {
      title: "Medium",
      dataIndex: "medium",
      filteredValue: state.filteredInfo.medium || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("medium", "Medium"),
      width: 110,
      align: "center",
      sorter: (a, b) => a.medium - b.medium,
      sortOrder:
        state.sortingOptions.columnKey === "medium" &&
        state.sortingOptions.order,
    },
    {
      title: "Hard",
      dataIndex: "hard",
      filteredValue: state.filteredInfo.hard || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("hard", "Hard"),
      width: 100,
      align: "center",
      sorter: (a, b) => a.hard - b.hard,
      sortOrder:
        state.sortingOptions.columnKey === "hard" && state.sortingOptions.order,
    },
    {
      title: "Number of essay questions",
      dataIndex: "n_essay",
      filteredValue: state.filteredInfo.n_essay || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("n_essay", "Number of essay questions"),
      width: 200,
      align: "center",
      sorter: (a, b) => a.n_essay - b.n_essay,
      sortOrder:
        state.sortingOptions.columnKey === "n_essay" &&
        state.sortingOptions.order,
    },
    {
      title: "Total",
      dataIndex: "total",
      filteredValue: state.filteredInfo.total || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("total", "الإجمالي"),
      width: 110,
      align: "center",
      sorter: (a, b) => a.total - b.total,
      sortOrder:
        state.sortingOptions.columnKey === "total" &&
        state.sortingOptions.order,
    },
    {
      title: "Creation date",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "Creation date"),
      width: 150,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title:
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_addquestion")
        )
          ? "Control"
          : "",
      dataIndex: "",
      width:
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_addquestion")
        )
          ? 350
          : 0,
      align: "center",
      //fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        return (
          (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("questionbank_addquestion")
            )) && (
            <div className="m-0 flex  justify-center gap-10">
              <Link
                to={`/admin/question-bank/${record.key}/add-essay-question`}
                className="w-fit rounded-2xl border-2 border-green-700 bg-green-700 px-4 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-green-700 active:scale-90"
              >
                Add essay questions
              </Link>
              <Link
                to={`/admin/question-bank/${record.key}/add-choose-question`}
                className="w-fit rounded-2xl border-2 border-accent bg-accent px-4 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              >
                Add choose questions
              </Link>
            </div>
          )
        );
      },
    },
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_deletecategory")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm deletion action?"
                  >
                    <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_updatecategory")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];

  const StudentQuestionBankColumns = [
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_deletecategory")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm deletion action?"
                  >
                    <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_updatecategory")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
    {
      title:
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_addquestion")
        )
          ? "التحكم"
          : "",
      dataIndex: "",
      width:
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_addquestion")
        )
          ? 350
          : 0,
      align: "center",
      //fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        return (
          (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("questionbank_addquestion")
            )) && (
            <div className="m-0 flex  justify-center gap-10">
              <Link
                to={`/admin/question-bank/${record.key}/add-essay-question`}
                className="w-fit rounded-2xl border-2 border-green-700 bg-green-700 px-4 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-green-700 active:scale-90"
              >
                اضافة اسئلة مقالية
              </Link>
              <Link
                to={`/admin/question-bank/${record.key}/add-choose-question`}
                className="w-fit rounded-2xl border-2 border-accent bg-accent px-4 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              >
                اضافة اسئلة اختيارية
              </Link>
            </div>
          )
        );
      },
    },
    {
      title: "Creation date",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "Creation date"),
      width: 150,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "Total",
      dataIndex: "total",
      filteredValue: state.filteredInfo.total || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("total", "الإجمالي"),
      width: 110,
      align: "center",
      sorter: (a, b) => a.total - b.total,
      sortOrder:
        state.sortingOptions.columnKey === "total" &&
        state.sortingOptions.order,
    },
    {
      title: "عدد الاسئلة المقالية",
      dataIndex: "n_essay",
      filteredValue: state.filteredInfo.n_essay || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("n_essay", "عدد الاسئلة المقالية"),
      width: 200,
      align: "center",
      sorter: (a, b) => a.n_essay - b.n_essay,
      sortOrder:
        state.sortingOptions.columnKey === "n_essay" &&
        state.sortingOptions.order,
    },
    {
      title: "difficult",
      dataIndex: "hard",
      filteredValue: state.filteredInfo.hard || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("hard", "difficult"),
      width: 100,
      align: "center",
      sorter: (a, b) => a.hard - b.hard,
      sortOrder:
        state.sortingOptions.columnKey === "hard" && state.sortingOptions.order,
    },
    {
      title: "medium",
      dataIndex: "medium",
      filteredValue: state.filteredInfo.medium || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("medium", "medium"),
      width: 110,
      align: "center",
      sorter: (a, b) => a.medium - b.medium,
      sortOrder:
        state.sortingOptions.columnKey === "medium" &&
        state.sortingOptions.order,
    },
    {
      title: "Easy",
      dataIndex: "easy",
      filteredValue: state.filteredInfo.easy || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("easy", "Easy"),
      width: 100,
      align: "center",
      sorter: (a, b) => a.easy - b.easy,
      sortOrder:
        state.sortingOptions.columnKey === "easy" && state.sortingOptions.order,
    },
    {
      title: "Class",
      dataIndex: "stage",
      filteredValue: state.filteredInfo.stage || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("stage", "Class"),
      editTable: true,
      width: 150,
      align: "center",
      sorter: (a, b) => a.stage - b.stage,
      sortOrder:
        state.sortingOptions.columnKey === "stage" &&
        state.sortingOptions.order,
    },

    {
      title: "اسم التصميف",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "اسم التصميف"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
      render: (_, record) => {
        return AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("questionbank_viewquestionbycategory")
          ) ? (
          <Link to={`${record.key}`}>{record.name}</Link>
        ) : (
          <p>{record.name}</p>
        );
      },
    },
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 110,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];

  const AttendanceColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 80,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Group name",
      dataIndex: "groupname",
      filteredValue: state.filteredInfo.groupname || null,

      ...getColumnSearchProps("groupname", "Group name"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.groupname.length - b.groupname.length,
      sortOrder:
        state.sortingOptions.columnKey === "groupname" &&
        state.sortingOptions.order,
    },
    {
      title: "Classroom",
      dataIndex: "academiclevel",
      width: 150,
      align: "center",
      editTable: true,
      sorter: (a, b) => a.academiclevel.length - b.academiclevel.length,
      sortOrder:
        state.sortingOptions.columnKey === "academiclevel" &&
        state.sortingOptions.order,
      filters: [
        { text: "First grade", value: "First grade" },
        { text: "Second grade", value: "Second grade" },
        { text: "Third grade", value: "Third grade" },
      ],
      filteredValue: state.filteredInfo.academiclevel || null,
      onFilter: (value, record) => String(record.academiclevel).includes(value),
      render: (tag) => {
        const color = tag.includes("First")
          ? "#4285f4"
          : tag.includes("Second")
            ? "#f9b522"
            : "#1890ff";
        return (
          <Tag color={color} key={tag}>
            {tag}
          </Tag>
        );
      },
    },
  ];

  const assistantsColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,
      /**
      *  ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      */
      //responsive: ["lg"],
      ...getColumnSearchProps("name", "Name"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Phone number",
      dataIndex: "mobile",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.mobile || null,

      ...getColumnSearchProps("mobile", "Phone number"),
      editTable: false,
    },
    {
      title: "Picture",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (_, record) => (
        <div className="profile_pic h-28 w-28 overflow-hidden rounded-full border-2 border-secondary p-1">
          <img
            className="h-full w-full rounded-full object-cover object-top"
            src={record.profile_pic}
            alt={record.name}
          />
        </div>
      ),
    },
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            <Popconfirm
              okText="Confirm"
              okType="danger"
              cancelText="Cancel"
              color="#fecaca"
              //okButtonProps={{ loading: state.loading }}
              onConfirm={() => handleDelete(record)}
              title="Do you really want to delete Assistant data?"
            >
              <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                Delete
              </button>
            </Popconfirm>

            <button
              className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              onClick={() =>
                navigate(`/admin/assistants/edit-assistant/${record.key}`)
              }
            >
              Edit
            </button>
          </div>
        ) : null;
      },
    },
  ];
  const materialsColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 50,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      editTable: false,
      width: 200,
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (_, record) => (
        <p>
          {record.is_public === 1
            ? "Free"
            : record.is_public === 0
              ? "Paid"
              : ""}
        </p>
      ),
    },

    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("lecture_addmatrial")
              )) && (
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#fecaca"
                //okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleDelete(record)}
                title="Do you really want to delete the file?"
              >
                <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                  Delete
                </button>
              </Popconfirm>
            )}
            <a
              className="w-fit px-2 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              href={record.address}
              rel="noreferrer"
              target="_blank"
            >
              download
            </a>
          </div>
        ) : null;
      },
    },
  ];
  const sessionAttendanceColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "Name"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Phone number",
      dataIndex: "mobile",
      filteredValue: state.filteredInfo.mobile || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("mobile", "Phone number"),
      editTable: false,
      width: 140,
      align: "center",
      sorter: (a, b) => a.mobile - b.mobile,
      sortOrder:
        state.sortingOptions.columnKey === "mobile" &&
        state.sortingOptions.order,
    },
    {
      title: "Father's phone 1",
      dataIndex: "parent_mobile",
      filteredValue: state.filteredInfo.parent_mobile || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("parent_mobile", "Father's phone 1"),
      editTable: false,
      width: 150,
      align: "center",
      sorter: (a, b) => a.parent_mobile - b.parent_mobile,
      sortOrder:
        state.sortingOptions.columnKey === "parent_mobile" &&
        state.sortingOptions.order,
    },
    {
      title: "Father's phone 2",
      dataIndex: "parent_mobile2",
      filteredValue: state.filteredInfo.parent_mobile2 || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("parent_mobile2", "Father's phone 2"),
      editTable: false,
      width: 150,
      align: "center",
      sorter: (a, b) => a.parent_mobile2 - b.parent_mobile2,
      sortOrder:
        state.sortingOptions.columnKey === "parent_mobile2" &&
        state.sortingOptions.order,
    },
    {
      title: "Quiz degree",
      dataIndex: "quiz_degree",
      width: 130,
      align: "center",
      filteredValue: state.filteredInfo.quiz_degree || null,
      ...getColumnSearchProps("quiz_degree", "Quiz degree"),
      editTable: false,
    },
    {
      title: "Student's degree",
      dataIndex: "student_quiz_degree",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.student_quiz_degree || null,
      ...getColumnSearchProps("student_quiz_degree", "Student's degree"),
      editTable: true,
      render: (_, record) => {
        const handleClick = () => {
          handleQuizDoubleClicked(record);
        };
        return (
          <div className="h-full w-full">
            {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("attendance_view")
            ) ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("attendance_viewnumberofstudent")
            ) ? (
              <button
                className="flex h-16 w-full cursor-pointer items-center justify-center border-2 border-secondary"
                onClick={handleClick} // Single click for mobile
                // onDoubleClick={!isMobile ? handleClick : null}
              >
                {record.student_quiz_degree}
              </button>
            ) : (
              <div className="cursor-pointer ">
                {record.student_quiz_degree}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Assignment",
      dataIndex: "student_quiz_degree",
      width: 150,
      align: "center",
      render: (_, record) => (
        <div className="profile_pic flex h-auto  w-full flex-col items-center  justify-start gap-2">
          <label
            className={`flex w-full cursor-pointer items-center justify-between rounded-md px-2 transition-all duration-300 ease-in-out hover:bg-secondary hover:text-light ${
              record.homework === 1 && "bg-green-500 text-light"
            }`}
            htmlFor={`yes_${record.key}`}
          >
            <input
              onChange={(e) => handleUpdateHomework(record, e.target.value)}
              className="h-8 w-8"
              type="radio"
              name={record.key}
              value="1"
              id={`yes_${record.key}`}
              defaultChecked={record.homework === 1}
            />
            <span>Yes</span>
          </label>
          <label
            className={`flex w-full cursor-pointer items-center justify-between rounded-md px-2 transition-all duration-300 ease-in-out hover:bg-secondary hover:text-light ${
              record.homework === 0 && "bg-secondary text-light"
            }`}
            htmlFor={`no${record.key}`}
          >
            <input
              onChange={(e) => handleUpdateHomework(record, e.target.value)}
              className="h-8 w-8"
              type="radio"
              name={record.key}
              value="0"
              id={`no${record.key}`}
              defaultChecked={record.homework === 0}
            />
            <span>No</span>
          </label>
          <label
            className={`flex w-full cursor-pointer items-center justify-between rounded-md px-2 transition-all duration-300 ease-in-out hover:bg-secondary hover:text-light ${
              record.homework === 2 && "bg-accent text-light"
            }`}
            htmlFor={`not_Completed${record.key}`}
          >
            <input
              onChange={(e) => handleUpdateHomework(record, e.target.value)}
              className="h-8 w-8"
              type="radio"
              name={record.key}
              value="2"
              id={`not_Completed${record.key}`}
              defaultChecked={record.homework === 2}
            />
            <span>Not completed</span>
          </label>
        </div>
      ),
    },
    {
      title: "Attendance date",
      dataIndex: "attend_at",
      width: 170,
      align: "center",
      filteredValue: state.filteredInfo.attend_at || null,

      ...getColumnSearchProps("attend_at", "Attendance date"),
      editTable: false,
    },
    {
      title: "Card number",
      dataIndex: "magnetic_id",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.magnetic_id || null,

      ...getColumnSearchProps("magnetic_id", "Card number"),
      editTable: false,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 90,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <Popconfirm
            okText="Confirm"
            okType="danger"
            cancelText="Cancel"
            color="#fecaca"
            //okButtonProps={{ loading: state.loading }}
            onConfirm={() => handleDelete(record)}
            title="Do you really want to delete?"
          >
            <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
              Delete
            </button>
          </Popconfirm>
        ) : null;
      },
    },
  ];
  const studentHistoryColumns = [
    {
      title: "Attendance date",
      dataIndex: "attend_at",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.attend_at || null,
      ...getColumnSearchProps("attend_at", "Attendance date"),
      editTable: false,
    },
    {
      title: "المكان",
      dataIndex: "location",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.location || null,
      ...getColumnSearchProps("location", "المكان"),
      editTable: false,
    },
    {
      title: "اسم المحاضرة",
      dataIndex: "lecture_name",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.lecture_name || null,
      ...getColumnSearchProps("lecture_name", "اسم المحاضرة"),
      editTable: false,
    },
  ];
  const studentsColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Id",
      dataIndex: "key",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("key", "key"),
      sorter: (a, b) => a.key - b.key,
      sortOrder:
        state.sortingOptions.columnKey === "key" && state.sortingOptions.order,
    },
    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "Name"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email", "email"),
      filteredValue: state.filteredInfo.email || null,

      width: 200,
      align: "center",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.mobile || null,

      ...getColumnSearchProps("mobile", "Phone number"),
      editTable: false,
    },
    {
      title: "Mother phone",
      dataIndex: "mobilep2",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.mobilep2 || null,

      ...getColumnSearchProps("mobilep2", "mobilep2"),
      editTable: false,
    },

    {
      title: "Area",
      dataIndex: "city",
      filteredValue: state.filteredInfo.city || null,
      editTable: false,
      width: 150,
      align: "center",
    },

    {
      title: "School",
      dataIndex: "school",
      filteredValue: state.filteredInfo.school || null,

      editTable: false,
      width: 150,
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "type",
      filteredValue: state.filteredInfo.type || null,
      editTable: false,
      width: 100,
      align: "center",
    },
    {
      title: "Group",
      dataIndex: "group",
      filteredValue: state.filteredInfo.group || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("group", "Group"),
      editTable: false,
      width: 100,
      align: "center",
      sorter: (a, b) => a.group.length - b.group.length,
      sortOrder:
        state.sortingOptions.columnKey === "group" &&
        state.sortingOptions.order,
    },
    {
      title: "Attendance card",
      dataIndex: "magnetic_id",
      width: 150,
      align: "center",
    },

    {
      title: "Creation date",
      dataIndex: "created_at",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.created_at || null,

      ...getColumnSearchProps("created_at", "Creation date"),
      editTable: false,
    },
    {
      title: "Parent Id",
      dataIndex: "parent_id",
      width: 100,
      align: "center",
    },
    {
      title: "Options",
      dataIndex: "",
      width: 300,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-6">
            {record.is_login === 1 && (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleLogoutStudent(record)}
                    title="Do You Want To Logout this student?"
                  >
                    <button className="w-fit px-2 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      Logout
                    </button>
                  </Popconfirm>
                )}
              </>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("")
              )) && (
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#fecaca"
                //okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleBlockStudent(record)}
                title="Do You Want To Block This Student?"
              >
                <button className="w-fit px-2 rounded-2xl border-2 border-main bg-main py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-main active:scale-90">
                  Block
                </button>
              </Popconfirm>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("student_delete")
              )) && (
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#fecaca"
                //okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleDelete(record)}
                title="Do You Want To Delete?"
              >
                <button className="w-fit px-2 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                  Delete
                </button>
              </Popconfirm>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("student_delete")
              )) && (
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#fecaca"
                //okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleOutStudent(record)}
                title="Do You Want To Out Student?"
              >
                <button className="w-fit px-2 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                  Out
                </button>
              </Popconfirm>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("student_edit")
              )) && (
              <button
                className="w-fit px-2 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                onClick={() =>
                  navigate(`/admin/students/edit-student/${record.key}`)
                }
              >
                Edit
              </button>
            )}
          </div>
        ) : null;
      },
    },
  ];
  const studentsOnlineInGroupColumns = [
    {
      title: "Number",
      dataIndex: "numbering_group",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Id",
      dataIndex: "key",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("key", "key"),
      sorter: (a, b) => a.key - b.key,
      sortOrder:
        state.sortingOptions.columnKey === "key" && state.sortingOptions.order,
    },
    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "Name"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email", "email"),
      filteredValue: state.filteredInfo.email || null,

      width: 200,
      align: "center",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.mobile || null,

      ...getColumnSearchProps("mobile", "Phone number"),
      editTable: false,
    },
    {
      title: "Mother phone",
      dataIndex: "mobilep2",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.mobilep2 || null,

      ...getColumnSearchProps("mobilep2", "mobilep2"),
      editTable: false,
    },

    {
      title: "Area",
      dataIndex: "city",
      filteredValue: state.filteredInfo.city || null,
      editTable: false,
      width: 150,
      align: "center",
    },

    {
      title: "School",
      dataIndex: "school",
      filteredValue: state.filteredInfo.school || null,

      editTable: false,
      width: 150,
      align: "center",
    },

    {
      title: "Creation date",
      dataIndex: "created_at",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.created_at || null,

      ...getColumnSearchProps("created_at", "Creation date"),
      editTable: false,
    },
    {
      title: "Parent Id",
      dataIndex: "parent_id",
      width: 100,
      align: "center",
    },
    {
      title: "Options",
      dataIndex: "",
      width: 320,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-6">
            {record.is_login === 1 && (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleLogoutStudent(record)}
                    title="Do You Want To Logout this student?"
                  >
                    <button className="w-fit px-2 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      Logout
                    </button>
                  </Popconfirm>
                )}
              </>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("")
              )) && (
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#fecaca"
                //okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleBlockStudent(record)}
                title="Do You Want To Block This Student?"
              >
                <button className="w-fit px-2 rounded-2xl border-2 border-main bg-main py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-main active:scale-90">
                  Block
                </button>
              </Popconfirm>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("student_delete")
              )) && (
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#fecaca"
                //okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleDelete(record)}
                title="Do You Want To Delete?"
              >
                <button className="w-fit px-2 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                  Delete
                </button>
              </Popconfirm>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("student_delete")
              )) && (
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#fecaca"
                //okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleOutStudent(record)}
                title="Do You Want To Out Student?"
              >
                <button className="w-fit px-2 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                  Out
                </button>
              </Popconfirm>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("student_edit")
              )) && (
              <button
                className="w-fit px-2 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                onClick={() =>
                  navigate(`/admin/students/edit-student/${record.key}`)
                }
              >
                Edit
              </button>
            )}
          </div>
        ) : null;
      },
    },
  ];
  const homeworkSetsColumns = [
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",

      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addHw")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm deletion action?"
                  >
                    <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addHw")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
    {
      title: "difficult",
      dataIndex: "hard",
      filteredValue: state.filteredInfo.hard || null,
      ...getColumnSearchProps("hard", "difficult"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.hard - b.hard,
      sortOrder:
        state.sortingOptions.columnKey === "hard" && state.sortingOptions.order,
    },
    {
      title: "medium",
      dataIndex: "medium",
      filteredValue: state.filteredInfo.medium || null,
      ...getColumnSearchProps("medium", "medium"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.medium - b.medium,
      sortOrder:
        state.sortingOptions.columnKey === "medium" &&
        state.sortingOptions.order,
    },
    {
      title: "Easy",
      dataIndex: "easy",
      filteredValue: state.filteredInfo.easy || null,
      ...getColumnSearchProps("easy", "Easy"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.easy - b.easy,
      sortOrder:
        state.sortingOptions.columnKey === "easy" && state.sortingOptions.order,
    },
    {
      title: "Name",
      dataIndex: "category_name",
      filteredValue: state.filteredInfo.category_name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("category_name", "Name"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.category_name.length - b.category_name.length,
      sortOrder:
        state.sortingOptions.columnKey === "category_name" &&
        state.sortingOptions.order,
    },
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];
  const quizSetsColumns = [
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",

      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addquiz")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm deletion action?"
                  >
                    <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addquiz")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
    {
      title: "difficult",
      dataIndex: "hard",
      filteredValue: state.filteredInfo.hard || null,
      ...getColumnSearchProps("hard", "difficult"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.hard - b.hard,
      sortOrder:
        state.sortingOptions.columnKey === "hard" && state.sortingOptions.order,
    },
    {
      title: "medium",
      dataIndex: "medium",
      filteredValue: state.filteredInfo.medium || null,
      ...getColumnSearchProps("medium", "medium"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.medium - b.medium,
      sortOrder:
        state.sortingOptions.columnKey === "medium" &&
        state.sortingOptions.order,
    },
    {
      title: "Easy",
      dataIndex: "easy",
      filteredValue: state.filteredInfo.easy || null,
      ...getColumnSearchProps("easy", "Easy"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.easy - b.easy,
      sortOrder:
        state.sortingOptions.columnKey === "easy" && state.sortingOptions.order,
    },
    {
      title: "Name",
      dataIndex: "category_name",
      filteredValue: state.filteredInfo.category_name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("category_name", "Name"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.category_name.length - b.category_name.length,
      sortOrder:
        state.sortingOptions.columnKey === "category_name" &&
        state.sortingOptions.order,
    },
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
  ];
  const examSetsColumns = [
    {
      title: "number",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "number"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Category Name",
      dataIndex: "category_name",
      filteredValue: state.filteredInfo.category_name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("category_name", "Category Name"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.category_name.length - b.category_name.length,
      sortOrder:
        state.sortingOptions.columnKey === "category_name" &&
        state.sortingOptions.order,
    },
    {
      title: "Easy",
      dataIndex: "easy",
      filteredValue: state.filteredInfo.easy || null,
      ...getColumnSearchProps("easy", "Easy"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.easy - b.easy,
      sortOrder:
        state.sortingOptions.columnKey === "easy" && state.sortingOptions.order,
    },
    {
      title: "Medium",
      dataIndex: "medium",
      filteredValue: state.filteredInfo.medium || null,
      ...getColumnSearchProps("medium", "Medium"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.medium - b.medium,
      sortOrder:
        state.sortingOptions.columnKey === "medium" &&
        state.sortingOptions.order,
    },
    {
      title: "Hard",
      dataIndex: "hard",
      filteredValue: state.filteredInfo.hard || null,
      ...getColumnSearchProps("hard", "Hard"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.hard - b.hard,
      sortOrder:
        state.sortingOptions.columnKey === "hard" && state.sortingOptions.order,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",

      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addquiz")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancle"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm Delete Category ?"
                  >
                    <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_addquiz")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancle"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm Cancle?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-red-400 active:scale-90">
                    Cancle
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];
  const analyticsColumns = [
    {
      title: "Name",
      dataIndex: "teacher_name",
      filteredValue: state.filteredInfo.teacher_name || null,
      //responsive: ["lg"],
      ...getColumnSearchProps("teacher_name", "teacher_name"),
      editTable: false,
      width: 70,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "teacher_name" &&
        state.sortingOptions.order,
    },
    {
      title: "Photo",
      dataIndex: "teacher_name",
      width: 70,
      align: "center",
      render: (_, record) => (
        <div className="profile_pic h-[74px] w-[74px] overflow-hidden rounded-full border-2 border-secondary p-1">
          <img
            className="h-full w-full rounded-full object-cover object-top"
            src={record.teacher_img}
            alt={record.name}
          />
        </div>
      ),
    },
    {
      title: "Unreaded Messages",
      dataIndex: "number_message",
      // filteredValue: state.filteredInfo.number_message || null,
      ...getColumnSearchProps("number_message", "number_message"),
      width: 70,
      align: "center",
      sorter: (a, b) => a.number_message - b.number_message,
      sortOrder:
        state.sortingOptions.columnKey === "number_message" &&
        state.sortingOptions.order,
    },
    {
      title: "Lst Replied Message",
      dataIndex: "from",
      // filteredValue: state.filteredInfo.from || null,
      ...getColumnSearchProps("from", "from"),
      width: 70,
      align: "center",
      sorter: (a, b) => a.from - b.from,
      sortOrder:
        state.sortingOptions.columnKey === "from" && state.sortingOptions.order,
    },
  ];
  const sessionAbsenceColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "Name"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Phone number",
      dataIndex: "mobile",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.mobile || null,

      ...getColumnSearchProps("mobile", "Phone number"),
      editTable: false,
    },
    {
      title: "Father's phone 1",
      dataIndex: "parent_mobile",
      width: 130,
      align: "center",
      filteredValue: state.filteredInfo.parent_mobile || null,

      ...getColumnSearchProps("parent_mobile", "Phone number"),
      editTable: false,
    },
    {
      title: "Father's phone 2",
      dataIndex: "parent_mobile2",
      width: 130,
      align: "center",
      filteredValue: state.filteredInfo.parent_mobile2 || null,

      ...getColumnSearchProps("parent_mobile2", "Phone number"),
      editTable: false,
    },
  ];

  const lecturesColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 80,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Name",
      dataIndex: "student_name",
      filteredValue: state.filteredInfo.student_name || null,
      ...getColumnSearchProps("student_name", "Name"),
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "student_name" &&
        state.sortingOptions.order,
      render: (_, record) => {
        return (
          <Link to={`/admin/students/edit-student/${record.student_id}`}>
            {record.student_name}
          </Link>
        );
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      filteredValue: state.filteredInfo.mobile || null,
      ...getColumnSearchProps("mobile", "Number"),
      width: 200,
      // fixed: state.smallWindow ? false : "left",
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "mobile" &&
        state.sortingOptions.order,
    },
    {
      title: "Mother Mobile",
      dataIndex: "mobilep2",
      filteredValue: state.filteredInfo.mobilep2 || null,
      ...getColumnSearchProps("mobilep2", "Number"),
      width: 200,
      // fixed: state.smallWindow ? false : "left",
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "mobilep2" &&
        state.sortingOptions.order,
    },
    {
      title: "Quiz Degree",
      dataIndex: "quiz_degree",
      width: 150,
      align: "center",
    },
    {
      title: "Homework Degree",
      dataIndex: "homework_degree",
      width: 200,
      align: "center",
    },
    {
      title: "Method",
      dataIndex: "method",
      width: 200,
      align: "center",
    },
    {
      title: "Purchased date",
      dataIndex: "buy_at",
      width: 200,
      align: "center",
    },

    {
      title: "Expiry date",
      dataIndex: "expire_at",
      editTable: true,
      width: 200,
      align: "center",
    },
    {
      title: "Code",
      dataIndex: "code",
      editTable: true,
      width: 150,
      align: "center",
    },
    {
      title: "Added by",
      dataIndex: "added_by",
      editTable: true,
      width: 200,
      align: "center",
    },
    {
      title: "Options",
      dataIndex: "",
      width: 300,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_updatecategory")
                  )) && (
                  <Link
                    to={`/admin/lectures/sessions/${record.key}/edit-student`}
                    className="w-fit rounded-2xl border-2 border-accent bg-accent py-1 px-3 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                  >
                    Edit
                  </Link>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_deletecategory")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm deletion action?"
                  >
                    <button className="w-fit rounded-2xl border-2 border-secondary bg-secondary py-1 px-3 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {record.is_expire === 1 && (
                  <>
                    {(AuthState.userData?.admin?.roles[0]?.name ===
                      "superadmin" ||
                      AuthState.userData?.admin?.permissions?.some(
                        (permission) =>
                          permission.name.startsWith(
                            "questionbank_deletecategory"
                          )
                      )) && (
                      <Popconfirm
                        okText="Confirm"
                        okType="danger"
                        cancelText="Cancel"
                        color="#fecaca"
                        //okButtonProps={{ loading: state.loading }}
                        onConfirm={() => handleGiveChance(record)}
                        title="Confirm Give Chance?"
                      >
                        <button className="w-fit rounded-2xl border-2 border-secondary bg-secondary py-1 px-3 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                          Give chance
                        </button>
                      </Popconfirm>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];

  const coursesColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 80,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "n"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Name",
      dataIndex: "student_name",
      filteredValue: state.filteredInfo.student_name || null,
      ...getColumnSearchProps("student_name", "Name"),
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "student_name" &&
        state.sortingOptions.order,
      render: (_, record) => {
        return (
          <Link to={`/admin/students/edit-student/${record.student_id}`}>
            {record.student_name}
          </Link>
        );
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      filteredValue: state.filteredInfo.mobile || null,
      ...getColumnSearchProps("mobile", "Number"),
      width: 200,
      // fixed: state.smallWindow ? false : "left",
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "mobile" &&
        state.sortingOptions.order,
    },
    {
      title: "Purchased date",
      dataIndex: "buy_at",
      width: 200,
      align: "center",
    },

    {
      title: "Expiry date",
      dataIndex: "expire_at",
      editTable: true,
      width: 200,
      align: "center",
    },
    {
      title: "Code",
      dataIndex: "code",
      editTable: true,
      width: 150,
      align: "center",
    },
    {
      title: "Added by",
      dataIndex: "added_by",
      editTable: true,
      width: 200,
      align: "center",
    },
    {
      title: "Options",
      dataIndex: "",
      width: 300,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_updatecategory")
                  )) && (
                  <Link
                    to={`/admin/courses/sessions/${record.key}/edit-student`}
                    className="w-fit rounded-2xl border-2 border-accent bg-accent py-1 px-3 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                  >
                    Edit
                  </Link>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_deletecategory")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm deletion action?"
                  >
                    <button className="w-fit rounded-2xl border-2 border-secondary bg-secondary py-1 px-3 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];

  const analyticsBlockingColumns = [
    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,
      ...getColumnSearchProps("name", "Name"),
      width: 200,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      editTable: true,
      width: 200,
      align: "center",
    },
    {
      title: "Mobile Parent",
      dataIndex: "mobilep",
      editTable: true,
      width: 200,
      align: "center",
    },
    {
      title: "Location",
      dataIndex: "loction",
      editTable: true,
      width: 200,
      align: "center",
    },
    {
      title: "Last Session",
      dataIndex: "last_session",
      editTable: true,
      width: 200,
      align: "center",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      editTable: true,
      width: 200,
      align: "center",
    },
    {
      title: "Options",
      editTable: true,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "right",

      render: (_, record) => (
        <>
          <Popconfirm
            okText="Confirm"
            okType="danger"
            cancelText="Cancel"
            color="#fecaca"
            //okButtonProps={{ loading: state.loading }}
            onConfirm={() => handleBlockStudent(record)}
            title="Do You Want To Block This Student?"
          >
            <button className="w-fit px-2 rounded-2xl border-2 border-main bg-main py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-main active:scale-90">
              Block
            </button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const studentsCoursesColumns = [
    {
      title: "n",
      dataIndex: "n",
      editTable: false,
      width: 80,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,
      ...getColumnSearchProps("name", "Name"),
      width: 200,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Email",
      dataIndex: "email",
      filteredValue: state.filteredInfo.email || null,
      ...getColumnSearchProps("email", "Name"),
      width: 200,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "email" &&
        state.sortingOptions.order,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      filteredValue: state.filteredInfo.mobile || null,
      ...getColumnSearchProps("mobile", "Number"),
      width: 200,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "mobile" &&
        state.sortingOptions.order,
    },
    {
      title: "Photo",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (_, record) => (
        <div className="profile_pic h-[74px] w-[74px] overflow-hidden rounded-full border-2 border-secondary p-1">
          <img
            className="h-full w-full rounded-full object-cover object-top"
            src={record.profile_pic}
            alt={record.name}
          />
        </div>
      ),
    },
    {
      title: "Options",
      dataIndex: "",
      width: 300,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_updatecategory")
                  )) && (
                  <Link
                    to={`/admin/students-courses/edit-student/${record.key}`}
                    className="w-fit rounded-2xl border-2 border-accent bg-accent py-1 px-3 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                  >
                    Edit
                  </Link>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_deletecategory")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Confirm deletion action?"
                  >
                    <button className="w-fit rounded-2xl border-2 border-secondary bg-secondary py-1 px-3 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm cancellation of modification?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-blue-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];

  const purchaseHistoryColumns = [
    {
      title: "Lecture name",
      dataIndex: "lecture_name",
      filteredValue: state.filteredInfo.lecture_name || null,
      //responsive: ["lg"],
      ...getColumnSearchProps("lecture_name", "Lecture name"),
      width: 200,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "lecture_name" &&
        state.sortingOptions.order,
    },
    {
      title: "Quiz",
      dataIndex: "code",
      filteredValue: state.filteredInfo.code || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("code", "Exam"),
      width: 200,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "code" && state.sortingOptions.order,

      render: (_, record) => {
        return (
          <p>
            {record.student_quiz &&
              `${record.quiz_degree}/${record.student_quiz}`}
            {}
          </p>
        );
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      width: 200,
      align: "center",
    },
    {
      title: "Method",
      dataIndex: "method",
      width: 200,
      align: "center",
    },
    {
      title: "Added By",
      dataIndex: "added_by",
      width: 200,
      align: "center",
    },
    {
      title: "Homework",
      dataIndex: "homework_student",
      width: 200,
      align: "center",

      render: (_, record) => {
        return (
          <p>
            {record.is_center === 1 && `${record.homework_student}`}
            {record.homework_student &&
              record.is_center === 0 &&
              `${record.student_quiz}/${record.homework_degree}`}
            {}
          </p>
        );
      },
    },
    {
      title: "Attendance place",
      dataIndex: "location",

      width: 120,
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "time",
      filteredValue: state.filteredInfo.time || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("time", "Date"),
      width: 120,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "time" && state.sortingOptions.order,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 300,
      align: "center",
      render: (_, record) => {
        return state.data.length > 0 && record.is_center === 0 ? (
          <div className="m-0 w-full flex  justify-center gap-4">
            {record.is_change === 1 && (
              <Link
                to={`/admin/students/change-session/${record.id}/${record.stage}`}
                className="px-4 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              >
                Change Session
              </Link>
            )}

            <Link
              to={`/admin/lectures/sessions/${record.id}/edit-student`}
              className="px-4 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
            >
              Edit
            </Link>
            <Popconfirm
              okText="Confirm"
              okType="danger"
              cancelText="Cancle"
              color="#fecaca"
              //okButtonProps={{ loading: state.loading }}
              onConfirm={() => handleDelete(record)}
              title="Confirm delete action?"
            >
              <button className="px-4 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                Delete
              </button>
            </Popconfirm>
          </div>
        ) : null;
      },
    },
  ];
  const trialExamStudentColumns = [
    {
      title: "Exam name",
      dataIndex: "exam_name",
      //responsive: ["lg"],
      width: 200,
      align: "center",
    },
    {
      title: "Code",
      dataIndex: "code",
      width: 150,
      align: "center",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      width: 150,
      align: "center",
      render: (_, record) => {
        return <p>{record?.percentage}%</p>;
      },
    },
    {
      title: "Degree",
      dataIndex: "degree",
      width: 200,
      align: "center",

      render: (_, record) => {
        return (
          <p>
            {record.student_quiz &&
              `${record.exam_student}/${record.student_quiz}`}
            {}
          </p>
        );
      },
    },

    {
      title: "Submit At",
      dataIndex: "submit_at",
      width: 200,
      align: "center",
    },
  ];

  const exceptionStudentColumns = [
    {
      title: "Lecture name",
      dataIndex: "session_name",
      width: 200,
      align: "center",
    },

    {
      title: "Added By",
      dataIndex: "added_by",
      width: 200,
      align: "center",
    },

    {
      title: "Date",
      dataIndex: "date",
      width: 120,
      align: "center",
      render: (_, record) => (
        <p>{moment(record.date).format("DD-MM-YYYY hh:mm A")}</p>
      ),
    },
  ];
  const examExceptionStudentColumns = [
    {
      title: "Exam name",
      dataIndex: "exam_name",
      width: 200,
      align: "center",
    },
    {
      title: "Added By",
      dataIndex: "added_by",
      width: 200,
      align: "center",
    },

    {
      title: "Date",
      dataIndex: "date",
      width: 120,
      align: "center",
      render: (_, record) => (
        <p>{moment(record.date).format("DD-MM-YYYY hh:mm A")}</p>
      ),
    },
  ];
  const permissionStudentColumns = [
    {
      title: "Lecture name",
      dataIndex: "session_name",
      filteredValue: state.filteredInfo.session_name || null,
      //responsive: ["lg"],
      ...getColumnSearchProps("session_name", "Lecture name"),
      width: 200,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "session_name" &&
        state.sortingOptions.order,
    },

    {
      title: "Added By",
      dataIndex: "added_by",
      width: 200,
      align: "center",
    },

    {
      title: "Date",
      dataIndex: "date",
      width: 120,
      align: "center",
      render: (_, record) => (
        <p>{moment(record.date).format("DD-MM-YYYY hh:mm A")}</p>
      ),
    },
  ];

  const viewStudentExamColumns = [
    {
      title: "Number",
      dataIndex: "n",
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      ...getColumnSearchProps("n", "n"),
    },
    {
      title: "Student Name",
      dataIndex: "student_name",
      filteredValue: state.filteredInfo.student_name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("student_name", "student_name"),
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.student_name.length - b.student_name.length,
      sortOrder:
        state.sortingOptions.columnKey === "student_name" &&
        state.sortingOptions.order,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      filteredValue: state.filteredInfo.mobile || null,
      //responsive: ["lg"],
      ...getColumnSearchProps("mobile", "Mobile"),
      width: 150,
      align: "center",
    },

    {
      title: "Parent Mobile 1",
      dataIndex: "parent_mobile",
      width: 150,
      align: "center",
    },
    {
      title: "Parent Mobile 2",
      dataIndex: "parent_mobile2",
      width: 150,
      align: "center",
    },

    {
      title: "Student Degree",
      dataIndex: "student_degree",
      width: 150,
      align: "center",
      sorter: (a, b) => a.student_degree - b.student_degree,
      sortOrder:
        state.sortingOptions.columnKey === "student_degree" &&
        state.sortingOptions.order,
    },
    {
      title: "Degree",
      dataIndex: "degree",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.student_name || null,

      // sorter: (a, b) => a.degree.length - b.degree.length,
    },
    {
      title: "Code",
      dataIndex: "code",
      width: 150,
      align: "center",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      width: 150,
      align: "center",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      width: 150,
      align: "center",
    },

    {
      title: "Options",
      dataIndex: "",
      width: 360,
      align: "center",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 w-full flex  justify-center gap-4">
            {record.degree !== null && (
              <Link to={`/admin/exams/all-students/${record.key}/model-answer`}>
                <button className="w-60 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                  Model Answer
                </button>
              </Link>
            )}

            <Popconfirm
              okText="Confirm"
              okType="danger"
              cancelText="Cancle"
              color="#fecaca"
              //okButtonProps={{ loading: state.loading }}
              onConfirm={() => handleRetakeExam(record)}
              title="Confirm retake?"
            >
              <button className="w-60 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                Retake Exam
              </button>
            </Popconfirm>
            <Popconfirm
              okText="Confirm"
              okType="danger"
              cancelText="Cancle"
              color="#fecaca"
              //okButtonProps={{ loading: state.loading }}
              onConfirm={() => handleDelete(record)}
              title="Confirm delete action?"
            >
              <button className="px-4 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                Delete
              </button>
            </Popconfirm>
          </div>
        ) : null;
      },
    },
  ];

  const attendanceGroupColumns = [
    {
      title: "Group",
      dataIndex: "name",
      // width: 200,
      render: (_, record) => {
        return (
          <>
            <Link
              className="py-3"
              to={`/admin/center/attendance/${record.key}`}
            >
              {record.name}
            </Link>
          </>
        );
      },
    },
  ];

  // const attendanceGroupShowSessionsColumns = [
  //   {
  //     title: "Group",
  //     dataIndex: "name",
  //     // width: 200,
  //     render: (_, record) => {
  //       return (
  //         <>
  //           <Link
  //             className="py-3"
  //             to={`/admin/center/students/${params}/${record.key}`}
  //           >
  //             {record.name}
  //           </Link>
  //         </>
  //       );
  //     },
  //   },
  // ];
  let attendanceGroupShowSessionsColumns = [];

  if (
    tableFor === "attendanceGroupShowSessions" &&
    state?.data?.length !== 0 &&
    state?.data?.length !== undefined
  ) {
    attendanceGroupShowSessionsColumns =
      state?.data?.length !== 0
        ? Object.keys(state?.data[0])?.map((key) => ({
            [key]: state?.data[0][key],
            title: key,
            dataIndex: key,
            width: 200,
            align: "center",
          }))
        : [];
  }

  let examsGroupShowSessionsColumns = [];

  if (
    tableFor === "examsGroupShowSessions" &&
    state?.data?.length !== 0 &&
    state?.data?.length !== undefined
  ) {
    examsGroupShowSessionsColumns =
      state?.data?.length !== 0
        ? Object.keys(state?.data[0])?.map((key) => ({
            [key]: state?.data[0][key],
            title: key,
            dataIndex: key,
            width: 200,
            align: "center",
          }))
        : [];
  }

  let quizzesGroupShowSessionsColumns = [];

  if (
    tableFor === "quizzesGroupShowSessions" &&
    state?.data?.length !== 0 &&
    state?.data?.length !== undefined
  ) {
    quizzesGroupShowSessionsColumns =
      state?.data?.length !== 0
        ? Object.keys(state?.data[0])?.map((key) => ({
            [key]: state?.data[0][key],
            title: key,
            dataIndex: key,
            width: 200,
            align: "center",
          }))
        : [];
  }

  let onlineAttendanceGroupColumns = [];

  if (
    tableFor === "onlineAttendanceGroup" &&
    state?.data?.length !== 0 &&
    state?.data?.length !== undefined
  ) {
    onlineAttendanceGroupColumns =
      state?.data?.length !== 0
        ? Object.keys(state?.data[0])?.map((key) => ({
            [key]: state?.data[0][key],
            title: key,
            dataIndex: key,
            width: 200,
            align: "center",
          }))
        : [];
  }

  const quizzesGroupColumns = [
    {
      title: "Group",
      dataIndex: "name",
      // width: 200,
      render: (_, record) => {
        return (
          <>
            <Link className="py-3" to={`/admin/center/quizzes/${record.key}`}>
              {record.name}
            </Link>
          </>
        );
      },
    },
  ];

  const viewExamColumns = [
    {
      title: "Exam Name",
      dataIndex: "title",
      filteredValue: state.filteredInfo.name || null,
      editTable: false,
      width: 200,
      align: "center",
      render: (_, record) => (
        <Link to={`/admin/online/exams/${record.key}`}>{record?.title}</Link>
      ),
    },
  ];
  const allCenterExamsColumns = [
    {
      title: "Exam Name",
      dataIndex: "title",
      filteredValue: state.filteredInfo.name || null,
      editTable: false,
      width: 200,
      align: "center",
      render: (_, record) => (
        <Link
          to={`/admin/center/exams/${params}/${record.stage}/${record.key}`}
        >
          {record?.title}
        </Link>
      ),
    },
  ];

  const viewExamStudentsColumns = [
    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      ...getColumnSearchProps("name", "Name"),
      editTable: false,
      width: 200,
      align: "center",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      filteredValue: state.filteredInfo.mobile || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("mobile", "Mobile"),
      editTable: false,
      width: 140,
      align: "center",
      sorter: (a, b) => a.mobile - b.mobile,
      sortOrder:
        state.sortingOptions.columnKey === "mobile" &&
        state.sortingOptions.order,
    },

    {
      title: "Mother mobile",
      dataIndex: "mobilep1",
      filteredValue: state.filteredInfo.mobilep1 || null,

      ...getColumnSearchProps("mobilep1", "Mother mobile"),
      editTable: false,
      width: 100,
      align: "center",
    },

    {
      title: "Student's degree",
      dataIndex: "degree",
      width: 120,
      align: "center",
      editTable: true,
      render: (_, record) => {
        const handleClick = () => {
          handleQuizDoubleClicked(record);
        };
        return (
          <div className="h-full w-full">
            {record.is_online !== 1 ? (
              <button
                className="flex h-16 w-full cursor-pointer items-center justify-center border-2 border-secondary"
                onClick={handleClick} // Single click for mobile
                // onDoubleClick={!isMobile ? handleClick : null}
              >
                {record.degree}
              </button>
            ) : (
              <div className="cursor-pointer ">{record.degree}</div>
            )}
          </div>
        );
      },
    },
  ];

  // if (
  //   tableFor === "viewExam" &&
  //   state?.data?.length !== 0 &&
  //   state?.data?.length !== undefined
  // ) {
  //   viewExamColumns =
  //     state?.data?.length !== 0
  //       ? Object.keys(state?.data[0])?.map((key) => {
  //           let object = {
  //             [key]: state?.data[0][key],
  //             title: key,
  //             dataIndex: key,
  //             width: 200,
  //             align: "center",
  //             editTable: true,
  //           };

  //           return {
  //             ...object,
  //             render: (value, record) => {
  //               // Ensure both value and record are passed here
  //               const handleClick = () => {
  //                 handleQuizDoubleClicked(record);
  //               };

  //               const isEditable = record[key] === null || false; // Access the 'isEditable' field

  //               console.log(isEditable);

  //               return (
  //                 <div className="h-full w-full">
  //                   {isEditable ? (
  //                     <button
  //                       className="flex h-16 w-full cursor-pointer items-center justify-center border-2 border-secondary"
  //                       onClick={handleClick} // Single click for mobile
  //                       // onDoubleClick={!isMobile ? handleClick : null}
  //                     >
  //                       {value}
  //                     </button>
  //                   ) : (
  //                     value
  //                   )}
  //                 </div>
  //               );
  //             },
  //           };
  //         })
  //       : [];
  // }

  const examsGroupColumns = [
    {
      title: "Group",
      dataIndex: "name",
      // width: 200,
      render: (_, record) => {
        return (
          <>
            <Link
              className="py-3"
              to={`/admin/center/exams/${record.key}/${record.stage}`}
            >
              {record.name}
            </Link>
          </>
        );
      },
    },
  ];
  const studentFilterColumns = [
    {
      title: "Group",
      dataIndex: "name",

      render: (_, record) => {
        return (
          <>
            <Link
              className="py-3"
              to={`/admin/center/students/${params}/${record.key}`}
            >
              {record.name}
            </Link>
          </>
        );
      },
      // width: 200,
    },
  ];
  const groupsGroupColumns = [
    {
      title: "Group",
      dataIndex: "name",
      // width: 200,
    },
  ];
  //!----------Nested Table for attendance page

  const expandedRow = (row) => {
    const nestedSessionColumns = [
      {
        title: "Class name",
        dataIndex: "sessionname",
        filteredValue: state.filteredInfo.sessionname || null,

        //responsive: ["lg"],
        ...getColumnSearchProps("sessionname", "Class name"),
        editTable: true,
        width: 200,
        fixed: state.smallWindow ? false : "left",
        align: "center",
        sorter: (a, b) => a.sessionname.length - b.sessionname.length,
        sortOrder:
          state.sortingOptions.columnKey === "sessionname" &&
          state.sortingOptions.order,
      },
      {
        title: "Number of students",
        dataIndex: "studentsnum",
        width: 50,
        align: "center",
        filteredValue: state.filteredInfo.studentsnum || null,

        ...getColumnSearchProps("studentsnum", "Number of students"),
        sorter: (a, b) => a.studentsnum - b.studentsnum,
        sortOrder:
          state.sortingOptions.columnKey === "studentsnum" &&
          state.sortingOptions.order,
      },
      {
        title: "Attendance",
        width: 110,
        align: "center",
        //filteredValue: state.filteredInfo.email || null,
        editTable: false,
        render: (_, record) => {
          return (
            <Link
              className="w-[150px] rounded-2xl border-2 border-accent bg-accent px-3 py-0 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              to={`/admin/attendance/${row.key}/${record.key}`}
            >
              Show
            </Link>
          );
        },
      },
    ];

    const nestedLecturesColumns = [
      {
        title: "Attendance",
        width: 110,
        align: "center",
        //filteredValue: state.filteredInfo.email || null,
        editTable: false,
        render: (_, record) => {
          return (
            <Link
              className="w-[150px] rounded-2xl border-2 border-accent bg-accent px-3 py-0 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              to={`/admin/attendance/${row.key}/${record.key}`}
            >
              Show
            </Link>
          );
        },
      },
      {
        title: "Number of students",
        dataIndex: "studentsnum",
        width: 50,
        align: "center",
        filteredValue: state.filteredInfo.studentsnum || null,

        ...getColumnSearchProps("studentsnum", "Number of students"),
        sorter: (a, b) => a.studentsnum - b.studentsnum,
        sortOrder:
          state.sortingOptions.columnKey === "studentsnum" &&
          state.sortingOptions.order,
      },
      {
        title: "Class name",
        dataIndex: "sessionname",
        filteredValue: state.filteredInfo.sessionname || null,

        //responsive: ["lg"],
        ...getColumnSearchProps("sessionname", "Class name"),
        editTable: true,
        width: 200,
        fixed: state.smallWindow ? false : "left",
        align: "center",
        sorter: (a, b) => a.sessionname.length - b.sessionname.length,
        sortOrder:
          state.sortingOptions.columnKey === "sessionname" &&
          state.sortingOptions.order,
      },
    ];

    let sessionData;
    let rowKey = row.n - 1;
    sessionData = state.data.filter((row) => row.n === rowKey + 1)[0]?.sessions;

    return (
      <Table
        columns={
          tableFor === "lectures" ? nestedLecturesColumns : nestedSessionColumns
        }
        dataSource={sessionData}
        pagination={false}
      />
    );
  };

  switch (tableFor) {
    case "codes":
      columns = codesColumns;
      break;
    case "activationCodes":
      columns = activationCodesColumns;
      break;
    case "revisionCodes":
      columns = revisionCodesColumns;
      break;
    case "vodafoneCodes":
      columns = vodafoneCodesColumns;
      break;
    case "discount-students":
      columns = discountStudentsColumns;
      break;
    case "deletedStudents":
      columns = deletedStudentsColumns;
      break;
    case "blockedStudents":
      columns = blockedStudentsColumns;
      break;
    case "latedStudents":
      columns = latedStudentsColumns;
      break;
    case "groups":
      columns = groupsColumns;
      break;
    case "reservations":
      columns = reservationsColumns;
      break;
    case "reservationStudent":
      columns = reservationStudentColumns;
      break;
    case "appointmentStudent":
      columns = appointmentStudentColumns;
      break;
    case "appointments":
      columns = appointmentsColumns;
      break;
    case "QuestionBank":
      columns = QuestionBankColumns;
      break;
    case "studentQuestionBank":
      columns = StudentQuestionBankColumns;
      break;
    case "attendance":
      columns = AttendanceColumns;
      break;

    case "assistants":
      columns = assistantsColumns;
      break;
    case "students":
      columns = studentsColumns;
      break;
    case "studentsOnlineInGroup":
      columns = studentsOnlineInGroupColumns;
      break;
    case "sessionAttendance":
      columns = sessionAttendanceColumns;
      break;
    case "sessionAbsence":
      columns = sessionAbsenceColumns;
      break;
    case "studentHistory":
      columns = studentHistoryColumns;
      break;
    case "materials":
      columns = materialsColumns;
      break;
    case "homeworkSets":
      columns = homeworkSetsColumns;
      break;
    case "quizSets":
      columns = quizSetsColumns;
      break;
    case "examSets":
      columns = examSetsColumns;
      break;
    case "analytics":
      columns = analyticsColumns;
      break;
    case "lectures":
      columns = lecturesColumns;
      break;
    case "courses":
      columns = coursesColumns;
      break;
    case "students-courses":
      columns = studentsCoursesColumns;
      break;
    case "analyticsBlocking":
      columns = analyticsBlockingColumns;
      break;
    case "purchaseHistory":
      columns = purchaseHistoryColumns;
      break;
    case "absentstudnets":
      columns = absentstudnetsColumns;
      break;
    case "trialExamStudent":
      columns = trialExamStudentColumns;
      break;
    case "exceptionStudent":
      columns = exceptionStudentColumns;
      break;
    case "examExceptionStudent":
      columns = examExceptionStudentColumns;
      break;
    case "permissionStudent":
      columns = permissionStudentColumns;
      break;
    case "viewStudentExam":
      columns = viewStudentExamColumns;
      break;

    // center
    case "attendanceGroup":
      columns = attendanceGroupColumns;
      break;
    case "quizzesGroup":
      columns = quizzesGroupColumns;
      break;
    case "examsGroup":
      columns = examsGroupColumns;
      break;
    case "viewExam":
      columns = viewExamColumns;
      break;
    case "viewOnlineExamStudents":
      columns = viewExamStudentsColumns;
      break;
    case "viewCenterExamStudents":
      columns = viewExamStudentsColumns;
      break;
    case "allCenterExams":
      columns = allCenterExamsColumns;
      break;
    case "attendanceGroupShowSessions":
      columns = attendanceGroupShowSessionsColumns;
      break;
    case "examsGroupShowSessionsColumns":
      columns = examsGroupShowSessionsColumns;
      break;
    case "quizzesGroupShowSessions":
      columns = quizzesGroupShowSessionsColumns;
      break;
    case "onlineAttendanceGroup":
      columns = onlineAttendanceGroupColumns;
      break;
    case "studentFilter":
      columns = studentFilterColumns;
      break;
    case "groupsGroup":
      columns = groupsGroupColumns;
      break;

    default:
      columns = codesColumns;
      break;
  }
  const editableColumns = columns.map((col) => {
    if (!col.editTable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  // set sellection in table
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      dispatch({ type: "setSelectedRows", payload: selectedRows });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  //! buttons functions
  const handelSendAbsence = () => {
    let finalData = {
      lecture_id: params.lectureID,
      group_id: params.groupID,
      selected: state.selectedRows,
    };
    async function fetchData() {
      try {
        let res = await axios.post(
          process.env.REACT_APP_ADMIN_SEND_ABSENCE_API,
          finalData,
          {
            //TODO: FIX THIS
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.userData).admin_token
              }`,
            },
          }
        );
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
    fetchData();
  };

  const table = (
    <section>
      <div className="table-options  flex flex-row-reverse items-center justify-between gap-10 md:flex-col md:items-center">
        <div className="export-reset flex w-1/2 items-center justify-end gap-10 md:w-full md:flex-col md:items-center">
          {state?.data && (
            <button className="w-fit rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
              <CSVLink data={state?.data} filename={`${tableFor} Excel.csv`}>
                Export
              </CSVLink>
            </button>
          )}
          <button
            className="w-fit rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
            onClick={handleResetSortFilterSearch}
          >
            Reset the table
          </button>
        </div>
        <div className="search-wrapper w-1/2 md:w-full">
          <input
            dir="ltr"
            type="search"
            className="signin-inputs signin-input my-7 pl-4 "
            placeholder="Search..."
            onChange={handleGlobalSearch}
            value={state.query}
          />
        </div>
      </div>

      <div className=" w-full  overflow-hidden rounded-xl border-2  border-secondary">
        <Form form={form} component={false}>
          <Table
            columns={editableColumns}
            dataSource={state.data}
            components={{ body: { cell: editableCell } }}
            bordered
            onChange={handleTableChange}
            loading={state.loading}
            expandedRowRender={nested ? expandedRow : ""}
            rowSelection={
              noSelect
                ? null
                : {
                    type: "checkbox",
                    ...rowSelection,
                  }
            }
            pagination={{ position: ["bottomCenter"] }}
            // rowSelection={{
            //   type: "checkbox",
            //   onSelect: (record) => console.log(record),
            //   selectedRowKeys: state.selectedRows,
            //   onChange: (keys) => {
            //     dispatch({
            //       type: "setSelectedRows",
            //       payload: keys,
            //     });
            //   },
            //   selections: [
            //     Table.SELECTION_ALL,
            //     Table.SELECTION_NONE,
            //     Table.SELECTION_INVERT,
            //     {
            //       key: "even",
            //       text: "Select Even Rows",
            //       onSelect: (allKeys) => {
            //         const selectedKeys = allKeys?.filter(
            //           (key) => key % 2 === 0
            //         );
            //         dispatch({
            //           type: "setSelectedRows",
            //           payload: selectedKeys,
            //         });
            //       },
            //     },
            //     {
            //       key: "odd",
            //       text: "Select Odd Rows",
            //       onSelect: (allKeys) => {
            //         const selectedKeys = allKeys?.filter(
            //           (key) => key % 2 !== 0
            //         );
            //         dispatch({
            //           type: "setSelectedRows",
            //           payload: selectedKeys,
            //         });
            //       },
            //     },
            //     {
            //       key: "First Level",
            //       text: "Select First Level Students",
            //       onSelect: (allKeys) => {
            //         const selectedKeys = allKeys?.filter((key) => {
            //           const matched = state.data.find((item) => {
            //             return (
            //               item.key === key && item.academiclevel === "First"
            //             );
            //           });
            //           return matched;
            //         });
            //         dispatch({
            //           type: "setSelectedRows",
            //           payload: selectedKeys,
            //         });
            //       },
            //     },
            //   ],
            // }}
            scroll={{
              y: 540,
            }}

            /**expandable={{
              expandedRowRender: (record) => <p> {record.school}</p>,
              rowExpandable: (record) => record.school !== "Not Expandable",
            }}*/
          />
        </Form>
      </div>
      {tableFor === "sessionAbsence" && (
        <div className="mt-4 flex w-1/2 items-center justify-start gap-10 md:w-full md:flex-col md:items-center">
          <button
            className="w-fit rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
            disabled={state.selectedRows.length <= 0}
            onClick={handelSendAbsence}
          >
            Sending absences to parents
          </button>
        </div>
      )}
    </section>
  );
  return table;
}

export default useTable;
